.floating-text {
  position: absolute;
  left: 50%;
  bottom: 20%;
  transform: translateX(-50%);
  background: linear-gradient(90deg, #007bff, #0056b3);
  color: white;
  padding: 4px 10px;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  opacity: 0;
  z-index: 10;
  white-space: nowrap;
  pointer-events: none;
  box-shadow: 0 2px 10px rgba(0, 123, 255, 0.3);
  letter-spacing: 0.5px;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.floating-text.visible {
  animation: floatAndFade 4s ease-out forwards;
}

@keyframes floatAndFade {
  0% {
    opacity: 0;
    bottom: 20%;
    transform: translateX(-50%) scale(0.9);
  }
  20% {
    opacity: 1;
    bottom: 30%;
    transform: translateX(-50%) scale(1);
  }
  80% {
    opacity: 1;
    bottom: 50%;
    transform: translateX(-50%) scale(1);
  }
  100% {
    opacity: 0;
    bottom: 50%;
    transform: translateX(-50%) scale(1.1);
  }
}
