@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 100%;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 100%;
  }

  #portfolio {
    width: 110%;
  }
}
@import url("https://fonts.googleapis.com/css2?family=Sawarabi+Gothic&family=Sen:wght@400..800&display=swap");
html {
  font-size: 62.5%;
}

body {
  color: #000;
  font-family: "Helvetica" !important;
  font-size: 1.6rem;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  line-height: 1.6;
}

img,
svg {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

a {
  color: #203a59;
  text-decoration: none;
  transition: all 0.3s ease 0s;
}
@media (min-width: 767.98px) {
  a:hover {
    text-decoration: none;
  }
}

* {
  box-sizing: border-box;
}

.spHdn {
  display: block !important;
}

.pcHdn {
  display: none !important;
}

@media (max-width: 767.98px) {
  body {
    font-size: 1.6rem;
  }
  body.is-fixed {
    overflow: hidden;
  }
  .spHdn {
    display: none !important;
  }
  .pcHdn {
    display: block !important;
  }
}
main {
  width: 100%;
  overflow: hidden;
}

section {
  width: 100%;
  margin: 0 auto;
  padding: 60px 0 !important;
}
@media (max-width: 767.98px) {
  section {
    padding: 40px 0 !important;
  }
}
.contInner {
  width: 100%;
  max-width: 1360px !important;
  margin: 0 auto !important;
}
@media (max-width: 767.98px) {
  .contInner {
    width: auto;
    margin: 0 15px !important;
  }
}
section.fadeUP {
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s;
  transform: translateY(50px);
}
section.fadeUP.is-active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.banner-area {
  display: none;
}

.section-padding {
  padding: 0;
}

.container {
  max-width: initial;
  padding: 0;
}

.spbr {
  display: none;
}

.pcbr {
  display: block;
}

@media (max-width: 767.98px) {
  .spbr {
    display: block;
  }
  .pcbr {
    display: none;
  }
}
.navbar-hidden {
  top: -100px !important;
  transition: top 0.6s;
}
.navbar-fixed {
  width: 100% !important;
  max-width: 100% !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  border-radius: 0 !important;
  .container {
    width: 90% !important;
    max-width: 1600px !important;
  }
}
.swiper {
  position: relative;
  margin-left: initial;
  margin-right: initial;
  width: 100%;
  height: 660px;
}
.swiper01 {
  height: auto !important;
}
.swiper04 {
  height: auto !important;
}
.swiper05 {
  height: auto !important;
}
.swiper07 {
  height: auto !important;
}
.swiper02 {
  height: auto !important;
}
.swiper09 {
  height: auto !important;
}
.swiper10 {
  height: auto !important;
}
.swiper11 {
  height: auto !important;
  padding: 0 5px !important;
}
.swiper07 .swiper-slide img {
  height: auto !important;
  position: relative !important;
  width: 80px !important;
}
.swiper10 .swiper-slide img {
  height: 80px !important;
  position: relative !important;
  width: 250px !important;
  object-fit: contain !important;
}
.swiper10 .swiper-slide {
  text-align: center;
}
.swiper10 .swiper-slide span {
  text-align: center;
  align-items: center;
  color: #3678f7;
  font-size: 1.8rem;
  font-weight: 700;
}
.swiper07 .swiper-slide img {
  height: auto !important;
  position: relative !important;
  width: 80px !important;
}
.swiper05 .swiper-wrapper .swiper-slide > div:first-child {
  z-index: 99;
}
.modal-youtube {
  width: 90%; /* or any custom width */
  max-width: 1200px; /* or any custom max width */
}
#menu .dropdown {
  height: 45px;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  margin: 20px 20px;
}
#menu .dropdown #dropdownMenuButton {
  margin: auto;
  padding: 0;
  background: transparent;
  border-radius: 30px;
  display: flex;
  box-shadow: none;
  img {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
      rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
    border-radius: 32px;
    margin-right: 10px;
    margin-left: 5px;
  }
  p {
    padding: 5px;
    font-size: 1.6rem;
  }
}

#menu .dropdown .dropdown-menu {
}
#menu .dropdown .dropdown-item {
  padding: 10px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: #f6f3f9;
  }
  img {
    margin-right: 20px;
  }
}
@media (max-width: 767.98px) {
  .swiper {
    height: initial;
    padding-bottom: 160px;
  }
}
.swiper .swiper-wrapper {
  position: relative;
  width: 100%;
  z-index: 1;
}
@media (max-width: 767.98px) {
  .swiper .swiper-wrapper {
    position: relative;
    /* height: 365px; */
  }
}
.swiper .swiper-slide {
  position: relative;
  width: 100%;
  height: auto;
}
.header-swiper {
  position: absolute !important;
  z-index: 0 !important;
}
.header-swiper .swiper .swiper-slide {
  height: 660px;
}
.swiper .swiper-slide:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0;
}
.btn-login {
  display: flex;
  width: 150px;
  height: 45px;
  border-radius: 30px;
  color: #3678f7;
  background-color: #fff;
  font-size: 1.6rem;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  border: 1px solid #3678f7;
  &:hover {
    background-color: #3678f7;
    color: #fff;
    border: none;
  }
}
.btn-sign-up {
  align-items: center;
  /* align-items: center; */
  justify-content: center;
  /* display: flex; */
  font-size: 1.6rem !important;
  /* font-weight: bolder; */
  text-transform: uppercase;
  width: 150px;
  height: 45px;
  border-radius: 30px !important;
  color: #fff !important;
  margin-left: 5px;
  padding: 11px !important;
  border-radius: 30px;
  border-color: #fff;
  /* background: url("./assets/header/ico_arrow.svg") no-repeat right 15px top 18px */
  /* #3678f7; */
  background: #3678f7;
  &:hover {
    background: #fff;
    color: #3678f7 !important;
    border: 1px solid #3678f7;
    text-decoration: none !important;
  }
}
@media (max-width: 768px) {
  .btn-sign-up {
    margin-top: 20px;
    margin-left: 0px;
  }
}
.header-swiper .swiper-slide:after {
  background: rgba(0, 0, 0, 0.4);
}
@media (max-width: 767.98px) {
  .swiper05 .swiper-slide {
    height: 300px !important;
  }
  #free_usage_all_service .swiperWrap .swiper-slide {
    height: 540px !important;
  }
}

.swiper .swiper-slide img {
  position: absolute;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.swiper .swiper-slide .bg {
  /* background-color: red; */
}
.swiper .swiper-slide button {
  position: absolute;
  width: 530px;
  height: 295px;
  top: 50%;
  margin-top: -104px;
  left: 58.75%;
  z-index: 3;
  padding: 0;
  img {
    position: relative;
  }
}
@media (max-width: 767.98px) {
  .swiper .swiper-slide button {
    width: 300px;
    height: 170px;
    margin-top: -185px;
    left: 50%;
    margin-left: -150px;
  }
}
.swiper .swiper-slide .ph {
  position: absolute;
  width: 530px;
  height: 356px;
  top: 60%;
  margin-top: -185px;
  left: 58.75%;
  z-index: 99;
}
@media (max-width: 767.98px) {
  .swiper .swiper-slide .ph {
    display: block;
    width: 300px;
    height: 200px;
    left: 50%;
    top: 50%;
    margin-left: -150px;
  }
}
@media (max-width: 767.98px) {
  .swiper .swiper-slide {
    width: 100%;
    /* height: 365px; */
  }
}

.swiper-button-prev:after,
.swiper-button-next:after {
  content: "" !important;
}

.swiper-button-prev {
  width: 45px;
  height: 39px;
  background-image: none;
  background: url("./assets/header/ico_prev.svg") no-repeat left top;
  background-size: contain;
}
@media (max-width: 767.98px) {
  .swiper-button-prev {
    top: 210px !important;
    left: 0;
    width: 35px;
    height: 30px;
  }
}

.swiper-button-next {
  width: 45px;
  height: 39px;
  background-image: none;
  background: url("./assets/header/ico_prev.svg") no-repeat left top;
  background-size: contain;
  transform: scale(-1, 1);
}
@media (max-width: 767.98px) {
  .swiper-button-next {
    top: 210px !important;
    right: 0;
    width: 35px;
    height: 30px;
  }
}

#mvMessage {
  max-width: 1600px;
  top: 180px;
  width: 100%;
  display: flex;
  top: 0;
  z-index: 2 !important;
  padding-top: 180px;
  padding-bottom: 180px;
  max-height: 660px;
}
#mvMessage .ph {
  width: 40%;
}
@media (max-width: 767.98px) {
  #mvMessage {
    position: relative;
    max-width: 100%;
    width: 100%;
    left: initial;
    padding: 15px;
    margin-bottom: 200px;
  }
}
#mvMessage .circleWrap {
  display: flex;
  margin-bottom: 15px;
}
#mvMessage .circleWrap .circle {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 64px;
  /* margin-left: -18px; */
}
#mvMessage .circleWrap .circle {
  width: 64px;
  height: 64px;
  margin-right: 10px;
  background: rgba(54, 120, 247, 0.475);
  border-radius: 32px;
  text-align: center;
  font-size: 1.6rem;
  font-style: italic;
  color: #fff;
  letter-spacing: 0;
}
/* #mvMessage .circleWrap .circle:before {
  position: absolute;
  content: "";
  width: 64px;
  height: 64px;
  margin-right: 20px;
  background: rgba(54, 120, 247, 0.475);
  border-radius: 32px;
  left: 50%;
  margin-left: -32px;
}
#mvMessage .circleWrap .circle:after {
  position: absolute;
  content: "";
  text-align: center;
  font-size: 2.1rem;
  font-style: italic;
  color: #fff;
  letter-spacing: 0;
} */
/* #mvMessage .circleWrap .circle:nth-child(1):after {
  position: absolute;
  content: "Instant";
}
#mvMessage .circleWrap .circle:nth-child(2):after {
  position: absolute;
  content: "Easy";
}
#mvMessage .circleWrap .circle:nth-child(3):after {
  position: absolute;
  content: "AI";
} */
#mvMessage .htg_tti01 {
  margin-bottom: 0.5em;
  font-weight: 600;
  font-size: 3.6rem;
  color: #fff;
  filter: drop-shadow(4px 4px 8px rgba(0, 0, 0, 0.161));
}
@media (max-width: 767.98px) {
  #mvMessage .htg_tti01 {
    font-size: 3.6rem;
  }
}
#mvMessage .txt {
  margin-bottom: 2em;
  font-size: 2rem;
  color: #fff;
  line-height: 1.4;
  letter-spacing: 0;
}
@media (max-width: 767.98px) {
  #mvMessage .txt {
    filter: drop-shadow(4px 4px 8px rgba(0, 0, 0, 0.161));
  }
}
#mvMessage .catch {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  padding: 2em 2.5em;
  border-radius: 14px;
  background: rgba(11, 38, 81, 0.41);
}
@media (max-width: 767.98px) {
  #mvMessage .catch {
    height: initial;
    padding: 1em 2.5em;
    text-align: center;
  }
}
#mvMessage .catch span {
  display: inline;
  font-weight: 500;
  font-size: 1.6rem;
  color: #fff;
  font-style: italic;
}
#mvMessage .catch:before,
#mvMessage .catch:after {
  position: absolute;
  content: "";
  width: 15px;
  height: 15px;
  background: url("./assets/header/ico_quot.svg") no-repeat left top;
}
#mvMessage .catch:before {
  position: absolute;
  content: "";
  left: 1em;
  top: 0;
  background: url("./assets/header/ico_quot.svg") no-repeat left top;
}
#mvMessage .catch:after {
  position: absolute;
  content: "";
  right: 1em;
  bottom: 0;
  background: url("./assets/header/ico_quot.svg") no-repeat left top;
  transform: rotate(180deg);
}

#startNow {
  position: relative;
  width: 100%;
  max-width: 1000px;
  border-radius: 10px;
  padding: 10px 30px;
  background: #fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  top: -50px;
  margin: 0 auto;
  z-index: 3;
}
@media screen and (max-width: 810px) {
  #startNow {
    padding: 10px 0px;
  }
  #startNow .inner {
    width: 100% !important;
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  #startNow .inner .btn {
    width: auto !important;
    padding-right: 30px;
    background: url("./assets/header/ico_arrow.svg") no-repeat right 15px top
      23px #3678f7 !important;
  }
}
#startNow .inner {
  width: 100%;
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}
#startNow .inner .ic {
  display: flex;
  max-width: 140px;
}
@media (max-width: 767.98px) {
  #startNow {
    width: 100% !important;
    margin: -150px 15px -80px;
    padding: 15px 25px 25px;
    left: initial;
    top: 0;
  }
}
#startNow .inner {
  display: flex;
  align-items: center;
}
@media (max-width: 767.98px) {
  #startNow .inner {
    display: contents;
    width: 100%;
  }
}
@media (max-width: 767.98px) {
  #startNow .inner .ic {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    max-width: 100% !important;
  }
  #startNow .inner .ic img {
    margin: auto;
  }
  #startNow .inner .btns {
    display: block !important;
  }
}
@media (max-width: 767.98px) {
  #startNow .inner .ic svg {
    width: 48px;
  }
}

@media (max-width: 767.98px) {
  #startNow .inner .ic .title.forSP {
    flex: 1;
    display: block;
    margin-left: 25px;
    font-size: 1.8rem;
    font-style: italic;
    letter-spacing: 0;
  }
}
#startNow .inner .ic .txtinput {
  margin: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 767.98px) {
  #startNow .inner .ic .txtinput {
    margin: 0;
  }
}
#startNow .inner .txtinput {
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
#startNow .inner .txtinput .title {
  font-size: 2.3rem;
  font-style: italic;
  margin-left: 10px;
  letter-spacing: 0;
  text-align: center;
}
#startNow .inner .btns {
  display: flex;
  justify-content: space-evenly !important;
}
@media (max-width: 767.98px) {
  #startNow .inner .txtinput .title.forPC {
    margin-left: 20px;
    font-size: 2rem;
  }
}
#startNow .inner .ic .txtinput input {
  width: 100%;
  padding: 8px 10px;
  margin-left: 1px;
}
@media (max-width: 767.98px) {
  #startNow .inner .ic .txtinput input {
    margin-bottom: 15px;
  }
}

#startNow .inner .btn,
.full-height .btn {
  display: grid;
  align-items: center;
  justify-content: start;
  width: 395px;
  height: 60px;
  padding-left: 20px;
  border-radius: 30px;
  background: url("./assets/header/ico_arrow.svg") no-repeat right 30px top 23px
    #3678f7;
}
#startNow .inner .btn {
  justify-content: center;
}
.full-height .btn {
  width: auto !important;
}
#startNow .inner .btn .sub {
  font-size: 1.6rem;
  font-weight: 500;
  color: #fff;
}
#startNow .inner .free {
  background: url("./assets/header/ico_arrow_left.svg") no-repeat left 30px top
    23px #3678f7;
}
@media (max-width: 767.98px) {
  #startNow .inner .btn {
    width: auto;
    margin-bottom: 10px;
    border-radius: 25px;
    background: url("./assets/header/ico_arrow.svg") no-repeat right 30px top
      18px #3678f7;
  }
}
#startNow .inner .btn span {
  font-weight: bold;
  font-size: 1.8rem;
  text-align: center;
  color: #fff;
}

.contInner .hdg_ttl02 {
  display: block;
  margin: 0 auto 50px;
  text-align: center;
  font-size: 3rem;
  font-weight: 700;
  line-height: 1;
}
@media (max-width: 767.98px) {
  .contInner .hdg_ttl02 {
    font-size: 2.8rem;
    margin: 0 auto 30px;
  }
  section#try_photo .contInner .hdg_ttl_sub {
    /* font-size: 1.8rem !important; */
  }
}
.contInner .hdg_ttl02 > div {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 0 65px;
}
@media (max-width: 767.98px) {
  .contInner .hdg_ttl02 > div {
    padding: 0 44px;
  }
}
.contInner .hdg_ttl02 > div > span {
  display: inline-block;
  font-family: "Helvetica" !important;
}
section .hdg_ttl02 > div > span .c {
  color: rgb(25, 90, 206);
}
.contInner .hdg_ttl02 > div:before,
.contInner .hdg_ttl02 > div:after {
  position: absolute;
  content: "";
  width: 50px;
  margin-top: -10px;
  height: 50px;
  background: url("./assets/header/ico_shine.svg") no-repeat left top;
  background-size: contain;
}
#contact .contInner .hdg_ttl02 > div:before,
#contact .contInner .hdg_ttl02 > div:after {
  background: url("./assets/header/ico_shine_white.svg") no-repeat left top;
  background-size: contain;
}
@media (max-width: 767.98px) {
  .contInner .hdg_ttl02 > div:before,
  .contInner .hdg_ttl02 > div:after {
    width: 36px;
    height: 36px;
    margin-top: -6px;
  }
}
.contInner .hdg_ttl02 > div:before {
  left: 0;
  top: 0;
}
.contInner .hdg_ttl02 > div:after {
  right: 0;
  top: 0;
}
.contInner .defBtn,
#how_it_work .contInner .defBtn {
  display: block;
}

.contInner .defBtn a,
#how_it_work .contInner .defBtn button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 400px;
  height: 60px;
  margin: 0 auto;
  border-radius: 30px;
  background: url("./assets/header/ico_arrow.svg") no-repeat right 30px top 23px
    #3678f7;
}
.contInner .defBtn a span,
#how_it_work .contInner .defBtn button span {
  display: block;
  font-size: 2.4rem;
  font-weight: 700;
  color: #fff;
}
@media (min-width: 767.98px) {
  .contInner .defBtn button:hover {
    background-color: #195ace;
  }
}
section#try_photo .contInner .hdg_ttl_sub {
  text-align: center;
  font-size: 2.4rem;
  font-weight: 700;
  margin-top: 10px;
  line-height: 20px;
}
section#why_us {
  max-width: initial;
  background: #f6f3f9;
  margin-top: -160px;
  padding-top: 170px !important;
}
section#why_us .swiper-button-prev,
section#why_us .swiper-button-next {
  display: none;
}
@media (max-width: 767.98px) {
  section#why_us {
    margin-top: 0;
    padding-top: 70px !important;
  }
  section#why_us .swiper01 {
    position: relative;
  }
  section#why_us .swiper01 .swiper-slide {
    display: flex;
    justify-content: center;
  }
  section#why_us .swiper01 .swiper-button-prev,
  section#why_us .swiper01 .swiper-button-next {
    display: block;
    position: absolute;
  }
  section#why_us .swiper01 .swiper-button-prev {
    left: 0px;
  }
  section#why_us .swiper01 .swiper-button-next {
    right: 0px;
  }
}
section#why_us .swiper01 {
  display: flex;
  width: auto;
  margin: 0 auto;
  justify-content: center;
}
@media screen and (min-width: 768px) {
  section#why_us .swiper01 {
    gap: 80px;
    max-width: 1360px;
  }
  section#why_us .swiper01 .swiper-slide {
    flex-shrink: initial;
    width: auto;
  }
}
@media (max-width: 767.98px) {
  section#why_us .swiper01 {
    gap: 0;
  }
  section#why_us .swiper01 .item > .inner {
    margin: 10px auto !important;
    padding: 10px 0 !important;

    height: 220px !important;
  }
  section#why_us .swiper01 .item .txt02 {
    padding-top: 50px;
  }
}
section#why_us .swiper01 > p {
  display: none;
}
section#why_us .swiper01 .swiper-slide img {
  width: 90px;
  object-fit: contain;
  height: auto;
  position: absolute;
  bottom: 0;
}
section#why_us .swiper01 .swiper-slide .i01 img {
  width: 75px;
}
section#why_us .swiper01 .item {
  position: relative;
  width: 300px;
  margin: auto;
  margin-top: 0px;
}
section#why_us .swiper01 .item .bg {
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 1;
}
section#why_us .swiper01 .item > .inner {
  position: relative;
  display: flex;
  flex-wrap: wrap;

  word-break: break-word;
  width: 85%;
  height: 270px;
  padding: 30px 0;
  margin: 30px auto;
  margin-top: 0px;
  z-index: 2;
  text-align: center;
  font-size: 1.8rem;
  color: #063fbb;
}
section#why_us .swiper01 .item > .inner .txt01 {
  width: 100%;
  font-style: italic;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  line-height: 1.4;
  font-size: 1.8rem;
}
section#why_us .swiper01 .item > .inner .icon {
  width: 100%;
  position: relative;
  display: flex;
  text-align: center;
  min-height: 70px;
  justify-content: center;
}
section#why_us .swiper01 .item .txt02 {
  line-height: 1.5;
}
@media (max-width: 767.98px) {
  section#try_photo {
    padding-bottom: 0 !important;
  }
}
section#try_photo .wrap {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 767.98px) {
  section#try_photo .wrap {
    display: block;
  }
}
section#try_photo .wrap .left {
  width: 50%;
  position: relative;
  max-height: 500px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #768091;
  border-radius: 10px;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.8);
}
section#try_photo .wrap .left .process_loading {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
section#try_photo .wrap .left img {
  max-height: 500px;
}
section#try_photo .wrap .left > div {
  max-height: 100% !important;
}
@media (max-width: 767.98px) {
  section#try_photo .wrap .left {
    width: auto;
    margin-bottom: 10px;
  }
}
section#try_photo .wrap .left img {
  width: 100% !important;
  margin: auto !important;
  height: 100%;
  object-fit: contain !important;
}
section#try_photo .wrap .right {
  width: 45%;
}
@media (max-width: 767.98px) {
  section#try_photo .wrap .right {
    width: auto;
    margin-bottom: 10px;
  }
  section#try_photo .wrap .right .txt01 {
    margin-top: 30px;
    margin-bottom: 15px;
  }
}
section#try_photo .wrap .right .txt01 {
  font-size: 1.6rem;
  text-align: left;
  line-height: 1.2;
}
section#try_photo .wrap .right .txt01 .red {
  color: #ff0000;
  display: inline;
}
section#try_photo .wrap .right .photoHere {
  text-align: right;
  font-weight: bold;
  font-size: 1rem;
  color: #3678f7;
  line-height: 1;
}
section#try_photo .wrap .right .codeWrap {
  position: relative;
  height: 178px;
  margin-bottom: 5px;
  border-radius: 20px;
  background: #edf1fc;
  border: 1px dashed #3678f7;
  padding: 65px 20px;
  cursor: pointer;
}
section#try_photo .wrap .right .codeWrap .wpforms-container-full {
  margin: 0;
}
section#try_photo .wrap .right .codeWrap .wpforms-field-label {
  display: none !important;
}
section#try_photo
  .wrap
  .right
  .codeWrap
  .wpforms-container
  div.wpforms-uploader {
  position: relative;
  border-radius: 20px;
  background: #edf1fc;
  border: 1px dashed #3678f7;
  padding: 65px 20px;
}
section#try_photo .wrap .right .codeWrap {
  .malware-protection {
    position: absolute;
    width: 90px;
    bottom: 10px;
    right: 10px;
    z-index: 1;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adds a subtle shadow */
    border-radius: 5px; /* Optional: adds rounded corners */
  }
}
section#try_photo .wrap .right .codeWrap .drag {
  position: absolute;
  top: 15px;
  width: 90%;
  text-align: center;
  color: #3678f7;
  font-weight: bold;
  font-style: italic;
  font-size: 1.8rem;
  z-index: 1;
  display: grid;
}
section#try_photo .wrap .right .codeWrap .drag .sub_drag {
  font-size: 1.6rem;
  font-weight: 400;
}
@media (max-width: 767.98px) {
  section#try_photo .wrap .right .codeWrap .drag {
    /* font-size: 16px; */
  }
  section#try_photo .wrap .right .codeWrap .drag .sub_drag {
    /* font-size: 12px; */
  }
}
section#try_photo .wrap .right .codeWrap #folder {
  position: absolute;
  bottom: 10px;
  z-index: 1;
  left: 50%;
  margin-left: -43px;
}
section#try_photo .wrap .right .codeWrap #folder img {
  width: 60px;
}
section#try_photo .wrap .right .codeWrap #upload-img-btn svg {
  display: none;
}
section#try_photo
  .wrap
  .right
  .codeWrap
  div.wpforms-container-full
  .wpforms-form
  * {
  color: #edf1fc;
}
section#try_photo .wrap .right #btnProcess {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 10px auto 10px;
  white-space: nowrap;
  font-weight: bold;
  font-size: 1.8rem;
  color: #fff;
}
section#try_photo .wrap .right #btnProcess button {
  width: 400px;
  /* font-size: 1.4rem; */
  font-weight: 700;
  height: 50px;
  margin-left: 20px;
  color: black;
  position: relative;
  border-radius: 30px;
}
section#try_photo .wrap .right #btnProcess button img {
  position: absolute;
  right: 40px;
  width: 25px;
  color: black;
}
section#try_photo .wrap .right #btnProcess span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 400px;
  height: 60px;
  padding-right: 15px;
  cursor: pointer;
  border-radius: 30px;
  text-decoration: none;
  background: #3678f7;
  position: relative;
}
section#try_photo .wrap .right #btnProcess span img {
  position: absolute;
  right: 40px;
  width: 20px;
  color: white;
}
@media (max-width: 767.98px) {
  section#try_photo .wrap .right #btnProcess span {
    min-width: 180px;
    height: 50px;
    font-size: 1.8rem;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 15px;
    border-radius: 25px;
    background: #3678f7;
  }
  section#try_photo .wrap .right #btnProcess button {
    min-width: 180px;
    font-size: 1.8rem;
    margin-top: 10px;
    padding: 0px;
    text-align: left;
    padding-left: 25px;
  }
  section#try_photo .wrap .right #btnProcess button img {
    right: 25px;
  }
}
section#try_photo .wrap .right #btnProcess a span {
  color: #fff;
  font-size: 2.4rem;
  font-weight: 700;
}
@media (max-width: 767.98px) {
  section#try_photo .wrap .right #btnProcess a span {
    font-size: 2rem;
  }
}
section#try_photo .wrap .right .forClick {
  text-align: center;
  font-weight: bold;
  justify-content: center;
  display: flex;
  font-size: 1.6rem;
  white-space: nowrap;
}
@media (max-width: 768px) {
  section#try_photo .wrap .right .forClick {
    /* font-size: 1.4rem; */
  }
}
section#try_photo .wrap .right .photoItems .item {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 10px;
}
section#try_photo .wrap .right .photoItems .item .i {
  width: 98px;
  height: 98px;
  cursor: pointer;
}
section#try_photo .wrap .right .photoItems img {
  margin: 0 auto;
  height: 100%;
}
@media (max-width: 767.98px) {
  div#services {
    padding-bottom: 0 !important;
  }
}
div#services > .contInner {
  background: rgb(246, 243, 249);
  padding: 50px 30px;
  border-radius: 10px;
  background: linear-gradient(to right, #c0def6 0%, #e5e4fe 100%);
  box-shadow: 10px 10px 6px rgba(0, 0, 0, 0.16);
}
@media (max-width: 767.98px) {
  div#services > .contInner {
    margin: 0 !important;
    padding: 50px 15px;
    border-radius: 0;
    box-shadow: none;
  }
}
div#services > .contInner .txt {
  padding: 0 8%;
  margin-bottom: 25px;
  text-align: left;
}
div#services > .contInner .block .txt {
  padding: 0;
  margin-bottom: 0px;
}
div#services > .contInner .seePrice {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}
@media (max-width: 767.98px) {
  div#services > .contInner .seePrice {
    display: block;
  }
}
div#services > .contInner .seePrice span {
  display: inline-block;
  font-size: 1.6rem;
  color: #3678f7;
  font-style: italic;
}
@media (max-width: 767.98px) {
  div#services > .contInner .seePrice span {
    line-height: 1.2;
    margin-bottom: 10px;
    font-size: 1.8rem;
  }
}
@media (max-width: 767.98px) {
  div#services > .contInner .seePrice .btn {
    width: 100%;
  }
}
div#services > .contInner .seePrice .btn a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 30px;
  border-radius: 15px;
  background: url("./assets/header/ico_arrow.svg") no-repeat right 15px top 8px
    #3678f7;
}
@media (max-width: 767.98px) {
  div#services > .contInner .seePrice .btn a {
    height: 45px;
    border-radius: 25px;
    width: 80%;
    margin: 0 auto;
    background: url("./assets/header/ico_arrow.svg") no-repeat right 15px top
      16px #3678f7;
  }
}
#header {
  background: #768091;
}
div#services > .contInner .seePrice .btn a span {
  font-size: 1.8rem;
  font-weight: 700;
  color: #fff;
  font-style: normal;

  margin-right: 5%;
}
@media (max-width: 767.98px) {
  div#services > .contInner .seePrice .btn a span {
    margin: 0;
  }
}
div#services > .contInner table,
div#services > .contInner td,
div#services > .contInner th {
  border: none;
  border-collapse: collapse;
  padding: 0;
}
div#services > .contInner tr {
  padding: 0;
}
div#services > .contInner .priceListSP {
  display: none;
}
@media (max-width: 767.98px) {
  div#services > .contInner .priceListSP {
    display: block;
    margin-bottom: 30px;
  }
  div#services > .contInner .priceListSP summary {
    position: relative;
    display: flex;
    align-items: center;
    align-items: stretch;
    margin-bottom: 15px;
  }
  div#services > .contInner .priceListSP summary::-webkit-details-marker {
    display: none;
  }
  div#services > .contInner .priceListSP summary:after {
    position: absolute;
    content: "";
    width: 10px;
    height: 20px;
    top: 50%;
    right: 20px;
    margin-top: -10px;
    background: url(./assets/header/ico_arrow.svg) no-repeat left top;
    background-size: contain;
    transform: rotate(90deg);
    transition: all 0.3s ease 0s;
  }
  div#services > .contInner .priceListSP summary .inner {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    padding: 15px;
    height: 80px;
    text-align: center;
  }
  div#services > .contInner .priceListSP summary .tag {
    display: flex;
    align-items: center;
    width: 100%;
  }
  div#services > .contInner .priceListSP summary .tag > span {
    display: block;
    align-items: center;
    justify-content: center;
    width: 140px;
    height: 24px;
    margin: 0 auto 5px;
    background: #fff;
    border-radius: 12px;
    font-size: 1.3rem;
    line-height: 24px;
    color: #4575b5;
    text-align: center;
    font-weight: 600;
  }
  div#services > .contInner .priceListSP summary .ttl {
    display: block;
    width: 100%;
    font-weight: bold;
    font-size: 1.8rem;
    color: #fff;
    line-height: 1;
  }
  div#services > .contInner .priceListSP summary .feature {
    width: 60%;
  }
  div#services > .contInner .priceListSP summary .feature .inner {
    display: block;
    height: 100%;
    background: #e6e6e6;
    padding: 15px;
  }
  div#services > .contInner .priceListSP summary .feature .inner .ttl {
    font-size: initial;
    font-weight: normal;
    color: #000;
  }
  div#services > .contInner .priceListSP summary .free {
    width: 100%;
    min-width: 155px;
  }
  div#services > .contInner .priceListSP summary .free .inner {
    height: 50px;
    background: #195ace;
  }
  div#services > .contInner .priceListSP summary .ai {
    width: 100%;
    min-width: 155px;
  }
  div#services > .contInner .priceListSP summary .ai .inner {
    height: 80px;
    background: #3678f7;
  }
  div#services > .contInner .priceListSP summary .ai .inner .tag {
    margin-bottom: 5px;
  }
  div#services > .contInner .priceListSP summary .ai .inner .tag span {
    color: #4575b5;
  }
  div#services > .contInner .priceListSP summary .team {
    width: 100%;
    min-width: 155px;
  }
  div#services > .contInner .priceListSP summary .team .inner {
    background: #203a59;
  }
  div#services > .contInner .priceListSP summary .team .inner .tag span {
    color: #203a59;
    margin-top: 5px;
  }
  div#services > .contInner .priceListSP summary .team .inner .ttl {
    position: relative;
    line-height: 1;
    /* padding-left: 35px; */
  }
  /* div#services > .contInner .priceListSP summary .team .inner .ttl:before {
    position: absolute;
    content: "";
    width: 20px;
    height: 5px;
    background: #fff;
    top: 50%;
    left: 10px;
    margin-top: -4px;
  } */
  /* div#services > .contInner .priceListSP summary .team .inner .ttl:after {
    position: absolute;
    content: "";
    width: 5px;
    height: 20px;
    background: #fff;
    top: 50%;
    left: 18px;
    margin-top: -12px;
  } */
  div#services
    > .contInner
    .priceListSP
    .details[open]
    .details__summary:after {
    transform: rotate(270deg);
  }
  div#services > .contInner .priceListSP .details__content {
    overflow: hidden;
  }
  div#services > .contInner .priceListSP .priceList {
    margin-top: -15px;
    margin-bottom: 15px;
    border: solid 1px #bfbfbf;
    width: 100%;
  }
  div#services > .contInner .priceListSP .priceList tr {
    display: flex;
    background: #fff;
  }
  div#services > .contInner .priceListSP .priceList tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  div#services > .contInner .priceListSP .priceList td {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40%;
    vertical-align: middle;
    padding: 15px;
    line-height: 1;
  }

  div#services > .contInner .priceListSP .priceList td .type {
    display: block;
    font-weight: 500;
    font-size: 1.2rem;
    color: #4575b5;
  }
  div#services > .contInner .priceListSP .priceList td a {
    font-size: 1.6rem;
    text-decoration: underline;
    color: #000;
  }
  div#services > .contInner .priceListSP .priceList td:nth-child(1) {
    flex: 1;
    text-align: left;
  }
  div#services > .contInner .priceListSP .priceList td:nth-child(1) > * {
    width: 100%;
  }
  div#services > .contInner .priceListSP .priceList td:not(:first-of-type) {
    border-left: solid 1px #bfbfbf;
  }
  div#services > .contInner .priceListSP .priceList td .tag {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 70px;
    height: 20px;
    margin-top: 5px;
    background: #000;
    border-radius: 5px;
  }
  div#services > .contInner .priceListSP .priceList td .tag span {
    font-size: 0.9rem;
    margin-left: 3px;
    margin-right: 2px !important;
    margin-top: 2px;
  }
  div#services > .contInner .priceListSP .priceList td .tag.service_active {
    background: #3678f7;
  }
  div#services > .contInner .priceListSP .priceList td .tag.service_coming {
    background: grey;
  }
  div#services > .contInner .priceList tbody tr .ai-support {
    padding: 5px 10px !important;
  }
  div#services > .contInner .priceListSP .priceList td .tag {
    font-size: 1rem;
    font-weight: 700;
    color: #fff;
    padding: 0px 5px;
  }
}
div#services > .contInner .priceListPC {
  width: 100%;
  margin-bottom: 50px;
}
@media (max-width: 767.98px) {
  div#services > .contInner .priceListPC {
    display: none;
  }
}
div#services > .contInner .priceListPC thead tr {
  display: flex;
  align-items: flex-end;
  width: 100%;
}
div#services > .contInner .priceListPC thead th {
  display: block;
  text-align: center;
  width: 20%;
}
div#services > .contInner .priceListPC thead th:nth-child(1) {
  width: 40%;
}
div#services > .contInner .priceListPC thead th .inner {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}
div#services > .contInner .priceListPC thead th .inner .tag {
  display: flex;
  align-items: end;
  width: 100%;
}
div#services > .contInner .priceListPC thead th .inner .tag span {
  display: block;
  align-items: end;
  justify-content: center;
  width: 140px;
  height: 24px;
  margin: 0 auto 5px;
  background: #fff;
  border-radius: 12px;
  font-size: 1.2rem;
  line-height: 24px;
  color: #4575b5;
}
div#services > .contInner .priceListPC thead th .inner .ttl {
  display: block;
  width: 100%;
  font-weight: bold;
  font-size: 1.8rem;
  color: #fff;
  line-height: 1;
}
div#services > .contInner .priceListPC thead th.feature {
  flex: 1;
}
div#services > .contInner .priceListPC thead th.feature .inner {
  height: 76px;
  background: #e6e6e6;
}
div#services > .contInner .priceListPC thead th.feature .inner .ttl {
  font-size: initial;
  font-weight: bold;
  color: #000;
}
div#services > .contInner .priceListPC thead th.free {
  min-width: 155px;
}
div#services > .contInner .priceListPC thead th.free .inner {
  height: 76px;
  background: #195ace;
}
div#services > .contInner .priceListPC thead th.ai {
  min-width: 155px;
}
div#services > .contInner .priceListPC thead th.ai .inner {
  height: 92px;
  background: #3678f7;
}
div#services > .contInner .priceListPC thead th.ai .inner .tag {
  margin-bottom: 5px;
}
div#services > .contInner .priceListPC thead th.ai .inner .tag span {
  color: #4575b5;
}
div#services > .contInner .priceListPC thead th.team {
  min-width: 155px;
}
div#services > .contInner .priceListPC thead th.team .inner {
  height: 110px;
  background: #203a59;
}
div#services > .contInner .priceListPC thead th.team .inner .price_tag {
  width: auto;
}
div#services > .contInner .priceListPC thead th.team .inner .price_tag span {
  width: 60px;
  color: #fff !important;
  background: #bd1550;
  height: 35px;
  margin-left: 10px;
  padding: 5px;
  font-size: 1.6rem;
  font-weight: 800;
}
@media (max-width: 767.98px) {
  div#services > .contInner .priceListSP summary .price_tag span {
    color: #fff !important;
    background: #bd1550;
    font-size: 1.6rem;
  }
}
div#services > .contInner .priceListPC thead th.team .inner .tag span {
  color: #203a59;
}
div#services > .contInner .priceListPC thead th.team .inner .ttl {
  position: relative;
  line-height: 1;
  padding-left: 22%;
  text-align: left;
  display: flex;
}
div#services > .contInner .priceListPC thead th.team .inner .ttl:before {
  position: absolute;
  content: "";
  width: 20px;
  height: 5px;
  background: #fff;
  top: 50%;
  left: 9%;
  margin-top: -4px;
}
div#services > .contInner .priceListPC thead th.team .inner .ttl:after {
  position: absolute;
  content: "";
  width: 5px;
  height: 20px;
  background: #fff;
  top: 50%;
  left: 12%;
  margin-top: -12px;
}
div#services > .contInner .priceListPC tbody tr {
  display: flex;
  background: #fff;
}
div#services > .contInner .priceListPC tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}
div#services > .contInner .priceListPC tbody tr td.active {
  display: block;
}
div#services > .contInner .priceList tbody tr td.active {
  display: block;
}
div#services > .contInner .priceListPC tbody tr .ai-support,
div#services > .contInner .priceList tbody tr .ai-support {
  justify-content: left;
  padding-left: 7%;
}
div#services > .contInner .priceListPC tbody tr td,
div#services > .contInner .priceList tbody tr td {
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  width: 20%;
  padding: 2% 3%;
  line-height: 1;
}
div#services > .contInner .priceListPC tbody tr td:nth-child(1) {
  width: 40%;
}

div#services > .contInner .priceListPC tbody tr td .type {
  display: block;
  font-weight: 500;
  font-size: 1.2rem;
  color: #4575b5;
}
div#services > .contInner .priceListPC tbody tr td {
  transition: all 0.8s ease;
}
div#services > .contInner .priceListPC tbody tr td span {
  font-size: 1.8rem;
  color: #000;
  font-weight: 400;
  display: flex;
  cursor: pointer;
}
div#services > .contInner .priceListSP tbody tr td span .price_tag {
  background: #bd1550 !important;
}
div#services > .contInner .priceListPC tbody tr td span .price_tag {
  padding: 5px;
  background: #bd1550;
  font-size: 1.6rem;
  font-weight: 800;
}
div#services > .contInner .priceListPC tbody tr td span .price_tag span {
  color: #fff !important;
  font-size: 1.6rem;
  font-weight: 800;
}
div#services > .contInner .priceListPC tbody tr td.active {
  margin: 20px 0px;
}

div#services > .contInner .priceList tbody tr td.active {
  margin: 20px 0px;
}

div#services > .contInner .priceListPC tbody tr td.active .compare_image {
  box-shadow: 10px 10px 6px rgba(0, 0, 0, 0.16);
  border-radius: 20px;
  padding: 0px;
  overflow: hidden;
}
div#services > .contInner .priceList tbody tr td.active .compare_image {
  box-shadow: 10px 10px 6px rgba(0, 0, 0, 0.16);
  border-radius: 20px;
  padding: 0px;
  overflow: hidden;
}
div#services > .contInner .priceListPC tbody tr td.active span {
  color: #195ace;
  font-size: 1.8rem;
}
div#services > .contInner .priceList tbody tr td.active span {
  color: #195ace;
  font-size: 2rem;
}
.btn > button,
.defBtn > button {
  border: none;
}
div#services > .contInner .priceListPC tbody tr td.active button,
div#services > .contInner .priceList tbody tr td.active button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 60px;
  padding-right: 15px;
  border: none;
  cursor: pointer;
  color: #fff;
  font-size: 1.8rem;
  font-weight: 900;
  border-radius: 30px;
  text-decoration: none;
  background: #f23674;
  position: relative;
  margin: auto;
}
div#services > .contInner .priceListPC tbody tr td.active button img,
div#services > .contInner .priceList tbody tr td.active button img {
  width: 15px;
  margin-right: 5px;
}
div#services
  > .contInner
  .priceListPC
  tbody
  tr
  td.active
  .detail
  .service-description
  span.description,
div#services
  > .contInner
  .priceList
  tbody
  tr
  td.active
  .detail
  .service-description
  span.description {
  font-size: 1.6rem;
  color: #000;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  word-break: break-word;
  word-wrap: break-word;
  display: block;
  margin: 20px;
  cursor: none;
  line-height: 2.5rem;
  text-decoration: none;
}
div#services > .contInner .priceListPC tbody tr td.active .detail,
div#services > .contInner .priceList tbody tr td.active .detail {
  margin-top: 30px;
  margin-left: 0px !important;
  margin-right: 0px !important;
}
div#services > .contInner .priceListPC tbody tr td:nth-child(1) {
  flex: 1;
  text-align: left;
}
div#services > .contInner .priceListPC tbody tr td:nth-child(1) > * {
  width: 100%;
}
div#services > .contInner .priceListPC tbody tr td:not(:first-of-type) {
  min-width: 155px;
  border-left: solid 1px #bfbfbf;
}
div#services > .contInner .priceListPC tbody tr td .tag,
div#services > .contInner .priceList tbody tr td .tag {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100px;
  height: 20px;
  margin-left: 5px;
  background: #000;
  border-radius: 5px;
  font-size: 1.2rem;
  font-weight: 700;
  padding: 0px 5px;
  color: #fff;
}

div#services > .contInner .priceListPC tbody tr td .tag.service_active {
  background: #3678f7;
}
div#services > .contInner .priceListPC tbody tr td .tag.service_coming {
  background: gray;
}
div#services > .contInner .block {
  position: relative;
  width: 100%;
  padding: 35px 50px;
  margin-bottom: 40px;
  border-radius: 10px;
  overflow: hidden;
  background: #fff;
  border: 2px solid #3678f7;
}
@media (max-width: 767.98px) {
  div#services > .contInner .block {
    padding: 20px;
  }
}
div#services > .contInner .block:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  top: -27px;
  right: 74px;
  border-style: solid;
  border-right: 19px solid transparent;
  border-left: 19px solid transparent;
  border-bottom: 27px solid #3678f7;
  border-top: 0;
}
@media (max-width: 767.98px) {
  div#services > .contInner .block:before {
    display: none;
  }
}
div#services > .contInner .block .inner {
  display: flex;
  margin-bottom: 20px;
  justify-content: center; /* Center children horizontally */
  align-items: center; /* Center children vertically */
}
@media (max-width: 767.98px) {
  div#services > .contInner .block .inner {
    display: block;
  }
}
div#services > .contInner .block .inner .left {
  display: flex;
  align-items: center;
  margin-right: 30px;
}
@media (max-width: 767.98px) {
  div#services > .contInner .block .inner .left {
    width: 100%;
    margin-right: 0;
    justify-content: center;
  }
  div#services > .contInner .block .inner .left svg {
    width: 80px;
    margin-left: 15px;
    margin-bottom: 10px;
  }
}
div#services > .contInner .block .inner .right {
  text-align: left;
}
div#services > .contInner .block .inner .right .ttl {
  font-style: italic;
  font-size: 2.4rem;
  color: #3678f7;
  margin-bottom: 10px;
}
div#services > .contInner .block .inner .right .txt p {
  margin-bottom: 3px;
}
@media (max-width: 767.98px) {
  div#services > .contInner .block .inner .right .ttl {
    text-align: center;
    line-height: 1.2;
    margin-bottom: 15px;
  }
}
div#services > .contInner .block .ph {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 45px;
}
@media (max-width: 767.98px) {
  div#services > .contInner .block .ph,
  div#free_usage_service .ph {
    display: block;
  }
}
div#free_usage_service .ph .item {
  margin-top: 25px;
}
div#free_usage_service .ph .item {
  max-height: 140px;
}
div#free_usage_service .ph .item:first-child {
  margin-top: 0px;
}
div#services > .contInner .block .ph .item,
div#free_usage_service .ph .item {
  position: relative;
}
div#services > .contInner .block .ph .item img,
div#free_usage_service .ph .item img {
  width: 100%;
}
div#services > .contInner .block .ph .item span,
div#free_usage_service .ph .item span {
  position: absolute;
  content: "";
  padding: 0 20px;
  height: 26px;
  line-height: 26px;
  top: 10px;
  left: 10px;
  border-radius: 5px;
  background: #3678f7;
  opacity: 0.79;
  color: #fff;
  text-align: center;
  font-size: 1.6rem;
}
@media (max-width: 767.98px) {
  div#services > .contInner .block .ph .item:not(:last-of-type) {
    margin-bottom: 50px;
  }
  div#free_usage_service .ph .item:not(:last-of-type) {
    margin-bottom: 10px;
  }
  div#free_usage_service .ph .item {
    margin-top: 25px;
    min-height: 110px;
  }
  div#free_usage_service .ph .item span {
    padding: 0 5px;
    font-size: 1.2rem;
  }
  div#free_usage_service {
    padding-left: 10px;
    padding-right: 0px;
  }
}

div#free_usage_service .ph .item:not(:last-of-type):after {
  width: 30px;
  height: 6px;
  right: initial;
  transform: rotate(90deg);
  bottom: -25px;
  left: 50%;
  margin-left: -12px;
  top: initial;
  margin-top: 0;
  position: absolute;
  content: "";
  background: url("./assets/header/ico_arrow02.svg") no-repeat left top;
  background-size: contain;
}
div#services > .contInner .block .ph .item:not(:last-of-type):after {
  position: absolute;
  content: "";
  width: 46px;
  height: 18px;
  background: url("./assets/header/ico_arrow02.svg") no-repeat left top;
  background-size: contain;
  right: -46px;
  top: 50%;
  margin-top: -9px;
}
@media (max-width: 767.98px) {
  div#services > .contInner .block .ph .item:not(:last-of-type):after,
  div#free_usage_service .ph .item:not(:last-of-type):after {
    width: 30px;
    height: 12px;
    right: initial;
    transform: rotate(90deg);
    bottom: -30px;
    left: 50%;
    margin-left: -12px;
    top: initial;
    margin-top: 0;
  }
  div#free_usage_service .ph .item:not(:last-of-type):after {
    height: 7px;
    bottom: -20px;
  }
}

section#pricing > .contInner .inner .pricing_filter {
  text-align: center;
}
section#pricing > .contInner .inner .pricing_filter_group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  text-transform: uppercase;
  color: #555;
  font-size: 1.6rem;
  max-width: 750px;
  background-color: initial;
  border: 1px solid #eff2f5;
  font-weight: 400;
  padding: 10px 5px;
  border-radius: 10px;
  margin: auto;
}
@media screen and (max-width: 767.98px) {
  section#pricing > .contInner .inner .pricing_filter_group {
    display: block;
    margin-bottom: 30px;
  }
}
section#pricing > .contInner .inner .pricing_filter_group .pricing_filter_item {
  color: #b5b5c3;
  padding: 0.75rem 1.5rem;
  min-width: 240px;
  cursor: pointer;
  &:hover {
    color: #3f4254;
    background-color: #e4e6ef;
  }
  border-radius: 5px;
}
section#pricing > .contInner .inner .pricing_currency {
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 767.98px) {
  section#pricing > .contInner .inner .pricing_currency {
    margin-top: 15px;
    justify-content: center;
  }
}
section#pricing > .contInner .inner .row,
section#pricing > .contInner .inner .price_plans {
  margin-top: 40px;
}
section#pricing > .contInner .inner .pricing_currency .form-check-label {
  margin: 0 10px;
}
section#pricing > .contInner .inner .pricing_filter_group .active {
  background-color: #3678f7;
  color: #fff;
}
section#pricing > .contInner .txt {
  width: 80%;
  margin: auto;
  font-size: 1.6rem;
  font-weight: 400;
  font-style: normal;
  line-height: 1.6;
}
section#pricing > .contInner .txt .txt_note {
  font-size: 1.6rem;
  color: #3678f7;
  font-style: italic;
  margin-top: 10px;
}
section#pricing .pricing-row {
  width: 90%;
  margin: auto;
}
section#pricing .pricing-row .pricing-item-border {
  padding: 20px;
}
section#pricing .pricing-row .pricing-item {
  padding: 25px 10px;
  position: relative;
  border: 1px dashed #3678f7;
  border-radius: 10px;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  &:hover {
    background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
    color: #fff;
  }
}
.ribbon {
  --f: 10px; /* control the folded part*/
  --r: 15px; /* control the ribbon shape */
  --t: 10px; /* the top offset */

  position: absolute;
  inset: var(--t) calc(-1 * var(--f)) auto auto;
  padding: 0 10px var(--f) calc(10px + var(--r));
  clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - var(--f)),
    calc(100% - var(--f)) 100%,
    calc(100% - var(--f)) calc(100% - var(--f)),
    0 calc(100% - var(--f)),
    var(--r) calc(50% - var(--f) / 2)
  );
  background: #bd1550;
  color: #fff;
  box-shadow: 0 calc(-1 * var(--f)) 0 inset #0005;
}
.right-ribbon {
  position: absolute;
  top: 0;
  right: 0;
  background: #f23674;
  box-shadow: 0 0 0 999px #f23674;
  padding: 5px;
  clip-path: inset(0 -100%);
  inset: 0 0 auto auto;
  transform-origin: 0 0;
  transform: translate(29.3%) rotate(45deg);
  font-size: 1.6rem;
  font-weight: bold;
}
section#pricing .pricing-row .pricing-item .plan_title {
  font-size: 1.8rem;
  font-weight: 800;
  margin-bottom: 0px;
}
section#pricing .pricing-row .pricing-item .plan_credit {
  width: 100px;
  border-radius: 20px;
  background: linear-gradient(to right, #c0def6 0%, #e5e4fe 100%);
  color: #3678f7;
  margin: auto;
  display: inline;
  padding: 10px;
  font-size: 1.8rem;
  font-weight: 700;
}
section#pricing .pricing-row .pricing-item .plan_action {
  width: 80%;
  border-radius: 10px;
  display: block;
  padding: 5px;
  margin: auto;
  background: #3678f7;
  font-size: 1.8rem;
  border: 1px dashed #3678f7;
  font-weight: 700;
  &:hover {
    background: #fff;
    color: #000;
  }
}
section#pricing .pricing-row .pricing-item .plan_des {
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 1.6rem;
  font-weight: 700;
  color: #f23674;
  opacity: 0.7;
}
section#pricing .pricing-row .pricing-item .plan_sub {
  margin-top: 0px;
  margin-bottom: 25px;
  font-size: 1.4rem;
}
section#pricing .pricing-row .pricing-item .plan_fee {
  font-size: 3rem;
  font-weight: bolder;
  margin-bottom: 20px;
  display: inline-flex;
  line-height: 1;
}
section#pricing .pricing-row .pricing-item .plan_fee .payment_type {
  font-size: 1.7rem;
  font-weight: 400;
  opacity: 0.7;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
/*  */
section#how_it_work {
  background: #f6f3f9;
}
section#how_it_work .swiper-button-prev,
section#how_it_work .swiper-button-next {
  display: none;
}
section#how_it_work .swiperWrap {
  margin: 0 50px;
}
.swiper04 .swiper-slide img {
  position: relative;
}
@media (max-width: 767.98px) {
  section#how_it_work .swiperWrap {
    margin: 0;
  }
  section#how_it_work .swiper04 {
    position: relative;
  }
  section#how_it_work .swiper04 .swiper-button-prev,
  section#how_it_work .swiper04 .swiper-button-next {
    display: block;
    position: absolute;
  }
  section#how_it_work .swiper04 .swiper-button-prev {
    top: 90px !important;
    left: 0px;
  }
  section#how_it_work .swiper04 .swiper-button-next {
    top: 90px !important;
    right: 0px;
  }
}
section#how_it_work .boxWrap {
  width: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 50px;
  margin: 0 40px 50px;
}
@media (max-width: 767.98px) {
  section#how_it_work .boxWrap {
    position: relative;
    display: inline-flex;
    gap: 0;
    margin: 0 0 10px;
  }
}
section#how_it_work .item {
  text-align: center;
}
@media (max-width: 767.98px) {
  section#how_it_work .item {
    padding: 0 40px;
  }
}
section#how_it_work .item .box__icon {
  position: relative;
  color: #000;
  border-style: dashed;
  border-width: 1px 1px 1px 1px;
  border-color: #f23674;
  border-radius: 100px 100px 100px 100px;
  width: 150px;
  height: 150px;
  text-align: center;
  margin: 0 auto 25px;
  padding: 10px 0 0 0;
}
section#how_it_work .item .box__icon:before {
  position: absolute;
  content: "";
  background-color: #f23674;
  right: 10px;
  bottom: 10px;
  width: 22px;
  height: 22px;
  border-radius: 11px;
  transform: rotate(0) scale(1);
}
section#how_it_work .item .box__icon:after {
  position: absolute;
  content: "";
  counter-increment: initial;
  color: #f23674;
  background: #fff;
  left: 0;
  top: 0;
  margin: 0 auto;
  width: 44px;
  height: 44px;
  line-height: 44px;
  border-radius: 22px;
  transform: rotate(0) scale(1);
  font-size: 2rem;
  font-weight: 700;
}
section#how_it_work .item.no01 .box__icon:after {
  content: "1";
  box-shadow: 0 10px 20px rgba(242, 54, 116, 0.4);
}
section#how_it_work .item.no02 .box__icon {
  border-color: #ffa03a;
}
section#how_it_work .item.no02 .box__icon:before {
  background-color: #ffa03a;
  left: 10px;
  top: 10px;
  transform: rotate(0) scale(1);
}
section#how_it_work .item.no02 .box__icon:after {
  color: #ffa03a;
  content: "2";
  left: inherit;
  top: initial;
  right: 0;
  bottom: 0;
  box-shadow: 0 10px 10px 0 rgba(255, 160, 58, 0.38);
  transform: rotate(0) scale(1);
}
section#how_it_work .item.no03 .box__icon {
  border-color: #1cacf3;
}
section#how_it_work .item.no03 .box__icon:before {
  background-color: #1cacf3;
}
section#how_it_work .item.no03 .box__icon:after {
  content: "3";
  color: #1cacf3;
  box-shadow: 0 10px 20px 0 rgba(28, 172, 243, 0.33);
}
section#how_it_work .item.no04 .box__icon {
  border-color: #15de9b;
}
section#how_it_work .item.no04 .box__icon:before {
  background-color: #15de9b;
  left: 10px;
  top: 10px;
  transform: rotate(0) scale(1);
}
section#how_it_work .item.no04 .box__icon:after {
  content: "4";
  color: #15de9b;
  left: inherit;
  top: initial;
  right: 0;
  bottom: 0;
  box-shadow: 0 10px 20px 0 rgba(21, 222, 155, 0.47);
  transform: rotate(0) scale(1);
}
section#how_it_work .box__title {
  font-size: 1.8rem;
  font-weight: 600;
}
section#how_it_work .box__subtitle {
  font-size: 1.4rem;
  line-height: 1.6;
  text-align: left;
}
section#sample {
  background: #dde9fd;
}
section#sample .txt {
  width: 100%;
  margin: 0 0 50px;
  font-size: 16px;
  line-height: 27px;
  text-align: center;
  color: #000;
}
@media (max-width: 767.98px) {
  section#sample .txt {
    text-align: left;
  }
}
section#sample .swiperWrap {
  position: relative;
}
@media (max-width: 767.98px) {
  /* section#sample .swiperWrap {
    margin-bottom: 30px;
  } */
}
section#sample .swiperWrap .swiper05 {
  position: relative;
  width: 90%;
  overflow: hidden;
}
section#sample .swiperWrap .swiper05 .swiper-wrapper {
  width: 100%;
  z-index: 1;
}
section#sample .swiperWrap .swiper05 .swiper-slide {
  position: relative;
  width: 100%;
}
section#sample .swiperWrap .swiper05 .swiper-slide .caption {
  font-size: 1.4rem;
  line-height: 20px;
  color: #3678f7;
  margin-top: 10px;
}
@media (max-width: 767.98px) {
  section#sample .swiperWrap .swiper05 .swiper-slide {
    width: 100%;
  }
}
section#sample .swiperWrap .swiper-button-prev,
section#sample .swiperWrap .swiper-button-next {
  position: absolute;
}
section#sample .swiperWrap .swiper-button-prev {
  left: 0px;
}
@media (max-width: 767.98px) {
  section#sample .swiperWrap .swiper-button-prev {
    top: 130px !important;
    left: 0;
  }
  section#sample .swiperWrap .swiper-button-next {
    right: 0px;
  }
}
section#sample .swiperWrap .swiper-button-next {
  right: 0px;
}
@media (max-width: 767.98px) {
  section#sample .swiperWrap .swiper-button-next {
    top: 130px !important;
    right: 0;
  }
}
section#about {
  background: #fff;
}
section#about .inner {
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
}
@media (max-width: 767.98px) {
  section#about .inner {
    display: block;
    margin: 0 auto 30px;
  }
}
section#about .inner .left {
  display: flex;
  align-items: center;
  width: 30%;
}
@media (max-width: 767.98px) {
  section#about .inner .left {
    width: 100%;
  }
  section#about .inner .left img {
    margin: 0 auto 25px;
  }
}
section#about .inner .right {
  width: 70%;
  text-align: left;
}
@media (max-width: 767.98px) {
  section#about .inner .right {
    width: 100%;
  }
}
section#cont07 {
  /* background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%); */
  background: #3678f7;
  padding: 80px 0 55px;
}
section#cont07 .swiperWrap {
  position: relative;
}
section#cont07 .swiperWrap .swiper07 {
  position: relative;
  width: 100%;
  overflow: hidden;
}
@media (max-width: 767.98px) {
  section#cont07 .swiperWrap .swiper07 {
    width: auto;
    margin: 0 30px;
  }
}
section#cont07 .swiperWrap .swiper07 .swiper-wrapper {
  width: 100%;
  height: 100%;
  z-index: 1;
}
section#cont07 .swiperWrap .swiper07 .swiper-slide {
  position: relative;
  background: #fff;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.22);
  padding: 25px;
  text-align: center;
  height: auto;
}
@media (max-width: 767.98px) {
  section#cont07 .swiperWrap .swiper07 .swiper-slide {
    width: auto;
    box-shadow: none;
  }
}
section#cont07 .swiperWrap .swiper07 .swiper-slide .txt {
  font-style: italic;
  font-size: 2rem;
  color: #3678f7;
}
section#cont07 .swiperWrap .swiper07 .swiper-slide .num {
  font-weight: 700;
  font-size: 4rem;
  color: #3678f7;
  font-style: italic;
}
@media (max-width: 767.98px) {
  section#cont07 .swiperWrap .swiper07 .swiper-slide {
    width: 100%;
  }
}
section#cont07 .swiperWrap .swiper-button-prev,
section#cont07 .swiperWrap .swiper-button-next {
  position: absolute;
}
section#cont07 .swiperWrap .swiper-button-prev {
  left: 0px;
}
@media (max-width: 767.98px) {
  section#cont07 .swiperWrap .swiper-button-prev {
    top: 125px !important;
    left: -5px;
  }
}
section#cont07 .swiperWrap .swiper-button-next {
  right: 0px;
}
@media (max-width: 767.98px) {
  section#cont07 .swiperWrap .swiper-button-next {
    top: 125px !important;
    right: -5px;
  }
}
section#cont07 .swiperWrap .swiper-button-prev {
  background: none;
  width: 0;
  height: 0;
  border-style: solid;
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
  border-right: 40px solid #ffffff;
  border-left: 0;
}
@media (max-width: 767.98px) {
  section#cont07 .swiperWrap .swiper-button-prev {
    border-top: 11px solid transparent;
    border-bottom: 11px solid transparent;
    border-right: 20px solid #ffffff;
  }
}
section#cont07 .swiperWrap .swiper-button-next {
  background: none;
  width: 0;
  height: 0;
  border-style: solid;
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
  border-right: 40px solid #ffffff;
  border-left: 0;
  transform: scale(-1, 1);
}
@media (max-width: 767.98px) {
  section#cont07 .swiperWrap .swiper-button-next {
    border-top: 11px solid transparent;
    border-bottom: 11px solid transparent;
    border-right: 20px solid #ffffff;
  }
}
section#faq .ac_list {
  width: 100%;
  max-width: 800px;
  border: solid 3px #fff;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 0 0 30px 0 rgba(43, 27, 154, 0.08);
  margin: 0 auto 20px;
}
section#faq .ac_list dt {
  display: flex;
  align-items: center;
  padding: 10px 25px;
  cursor: pointer;
}
@media (max-width: 767.98px) {
  section#faq .ac_list dt {
    padding: 10px 15px;
  }
}
section#faq .ac_list dt p {
  margin: 0;
  color: #131313;
  font-size: 1.8rem;
}
@media (max-width: 767.98px) {
  section#faq .ac_list dt p {
    font-size: 1.6rem;
  }
}
section#faq .ac_list dt a {
  color: #0056b3;
  font-size: 1.8rem;
  font-weight: bolder;
}
section#faq .ac_list dt p:first-of-type {
  flex: 1;
}
section#faq .ac_list dt p:last-of-type {
  width: 20px;
  margin-left: 20px;
}
@media (max-width: 767.98px) {
  section#faq .ac_list dt p:last-of-type {
    margin-left: 0;
  }
}
section#faq .ac_list dt i {
  width: 20px;
  height: 20px;
  text-align: center;
  transition: all 0.3s;
}
section#faq .ac_list dd {
  overflow: hidden;
  width: 100%;
  height: 0;
  padding: 0 25px;
  transition: all 0.3s ease;
}
@media (max-width: 767.98px) {
  section#faq .ac_list dd {
    padding: 0 15px;
  }
}
section#faq .ac_list.active {
  border: solid 3px #2b1b9a;
}
section#faq .ac_list.active dt i {
  transform: rotate(90deg);
  transition: all 0.3s;
}
section#faq .ac_list.active dd {
  height: auto;
  padding: 10px 25px;
}
@media (max-width: 767.98px) {
  section#faq .ac_list.active dd {
    padding: 10px 15px;
  }
}
section#reviews {
  background: #f6f4f9;
  padding: 60px 0 35px;
}
section#reviews .swiperWrap {
  position: relative;
}
section#reviews .swiperWrap .swiper09 {
  position: relative;
  width: 100%;
  overflow: hidden;
}
@media (max-width: 767px) {
  .navbar-nav .open .dropdown-menu {
    position: absolute;
  }
  #menu .dropdown {
    margin-left: 10px;
    margin-top: 45px;
  }
  .navbar-default .navbar-toggle {
    margin-top: 20px;
  }
}
section#reviews .swiperWrap .swiper09 .swiper-wrapper {
  width: 100%;
  height: 100%;
  z-index: 1;
}
section#reviews .swiperWrap .swiper09 .swiper-slide {
  display: flex;
  flex-wrap: wrap;
  height: auto;
  min-height: 300px;
  background-color: #fff;
  border-style: solid;
  border-width: 3px 3px 3px 3px;
  border-color: #fff;
  border-radius: 8px 8px 8px 8px;
  position: relative;
  padding: 25px 0 0 0;
}
section#reviews .swiperWrap .swiper09 .swiper-slide.swiper-slide-active {
  border-color: #f5f6fe;
  box-shadow: 0 0 30px 0 rgba(43, 27, 154, 0.14);
}
section#reviews .swiperWrap .swiper09 .swiper-slide .cont {
  width: 100%;
  height: 220px;
}
section#reviews .swiperWrap .swiper09 .swiper-slide .cont .title {
  padding: 10px 25px;
}
section#reviews .swiperWrap .swiper09 .swiper-slide .cont .title span {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.4;
}
section#reviews .swiperWrap .swiper09 .swiper-slide .cont .title.color01 span {
  color: #2b1b9a;
}
section#reviews .swiperWrap .swiper09 .swiper-slide .cont .title.color02 span {
  color: #49d5ab;
}
section#reviews .swiperWrap .swiper09 .swiper-slide .cont .title.color03 span {
  color: #e68069;
}
section#reviews .swiperWrap .swiper09 .swiper-slide .cont .txt {
  padding: 10px 25px 20px 25px;
}
section#reviews .swiperWrap .swiper09 .swiper-slide .review {
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 0 0 8px 8px;
  padding: 10px 25px;
}
section#reviews .swiperWrap .swiper09 .swiper-slide .review.color01 {
  background: #fff6f4;
}
section#reviews .swiperWrap .swiper09 .swiper-slide .review.color02 {
  background: #f5f6fe;
}
section#reviews .swiperWrap .swiper09 .swiper-slide .review.color03 {
  background: #f2fcf9;
}
section#reviews .swiperWrap .swiper09 .swiper-slide .review .left {
  flex: 1;
}
section#reviews .swiperWrap .swiper09 .swiper-slide .review .right {
  width: 120px;
}
section#reviews .swiperWrap .swiper09 .swiper-slide .review .right .star {
  display: flex;
  justify-content: flex-start;
}
section#reviews .swiperWrap .swiper09 .swiper-slide .review .author__name {
  font-size: 1.8rem;
  font-weight: 700;
}
@media (max-width: 767.98px) {
  section#reviews .swiperWrap .swiper09 .swiper-slide {
    width: 100%;
  }
}
section#reviews .swiperWrap .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
}
section#reviews .swiperWrap .swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background-color: #3bbeff;
}
section#reviews #n_130 {
  width: 50px;
  height: 50px;
  left: 551px;
  top: 7450px;
}
section#reviews #n_58_ml {
  width: 50px;
  height: 50px;
  left: 0px;
  top: 0px;
}
section#reviews #n_131_mm {
  width: 50px;
  height: 50px;
  left: 1007px;
  top: 7450px;
}
section#reviews #n_58_mn {
  width: 50px;
  height: 50px;
  left: 0px;
  top: 0px;
}
section#reviews #n_25_mo {
  width: 1138px;
  height: 552px;
  left: 231px;
  top: 7540px;
}
section#reviews .elementor-repeater-item-a73f5d7 .author__thumb__designation {
  background-color: #f5f6fe;
}
section#reviews .elementor-repeater-item-827cfbe .author__thumb__designation {
  background-color: #f2fcf9;
}
section#reviews .elementor-repeater-item-2897bd7 .author__thumb__designation {
  background-color: #fff6f4;
}
section#reviews .sldier-content-area .owl-nav {
  margin: 20px 0 0 0;
}
section#reviews .sldier-content-area .owl-nav > div {
  transition: 0.3s;
}
section#reviews .sldier-content-area .owl-dots > div {
  width: 8px;
  height: 8px;
  background-color: rgba(59, 190, 255, 0.2);
  border-radius: 50px 50px 50px 50px;
  transition: 0.3s;
  margin: 0 5px 0 5px;
}
section#reviews .sldier-content-area .owl-dots {
  margin: 0;
}
section#reviews .sldier-content-area .owl-dots > div.active,
section#reviews .sldier-content-area .owl-dots > div:hover {
  width: 12px;
  height: 12px;
  background-color: #3bbeff;
}
section#reviews .testmonial__quote {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5);
  height: 16px;
  display: initial;
  position: absolute;
  right: 20px;
  bottom: 7px;
  margin: 0;
  padding: 0;
}
section#reviews .testmonial__quote,
section#reviews .testmonial__quote img {
  transition: 0.3s;
}
section#reviews .author__thumb__designation {
  width: 100%;
  background-color: #f2fcf9;
  border-radius: 0 0 8px 8px;
  display: initial;
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 30px 30px 20px 30px;
  margin: 0;
}
section#reviews .author__thumb {
  width: 60px;
  height: 60px;
  border-style: none;
  display: none;
  position: absolute;
  top: -30px;
  padding: 0;
}
section#reviews .author__thumb img {
  border-radius: 50px 50px 50px 50px;
  width: 60px;
  height: 60px;
}
section#reviews .testmonial__description {
  margin: 0;
}
section#reviews .cliper_single_testmonial {
  background-color: #fff;
  border-style: solid;
  border-width: 3px 3px 3px 3px;
  border-color: #f5f6fe;
  border-radius: 8px 8px 8px 8px;
  position: relative;
  padding: 56px 30px 90px 30px;
  margin: 30px 0 30px 0;
}
section#reviews .cliper_single_testmonial:hover {
  box-shadow: 0 0 30px 0 rgba(43, 27, 154, 0.14);
}
section#reviews .cliper_single_testmonial:before {
  text-align: left;
}
section#reviews .cliper_single_testmonial:after {
  background-image: url("https://themeim.com/wp/cliper/wp-content/uploads/2021/11/testi_quote.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 30px;
  top: 20px;
  width: 49px;
  height: 48px;
  z-index: 1;
}
section#contact {
  width: 100%;

  text-align: center;
  padding: 60px 0 30px !important;
}
#contact form {
  max-width: 1000px;
  margin: auto;
}
@media (max-width: 767.98px) {
  section#contact {
    width: auto;
  }
}
section#contact .hdg_ttl02 {
  color: #fff;
}
section#contact #Contact_Us {
  display: block;
  margin: 0 auto 50px;
  text-align: center;
  font-size: 3rem;
  font-weight: 700;
  line-height: 1;
}
section#contact #Contact_Us span {
  color: #fff;
}
section#contact form .item {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 800px;
  margin: 0 auto 50px;
}
@media (max-width: 767.98px) {
  section#contact form .item {
    display: block;
    text-align: left;
  }
}
section#contact form .item .ttl {
  width: 150px;
  color: #fff;
  font-size: 1.8rem;
}
@media (max-width: 767.98px) {
  section#contact form .item .ttl {
    width: 100%;
  }
}
section#contact form .item input,
section#contact form .item textarea {
  flex: 1;
  width: 600px;
  padding: 8px 10px;
}
@media (max-width: 767.98px) {
  section#contact form .item input,
  section#contact form .item textarea {
    width: 100%;
  }
}
section#contact input[type="submit"] {
  display: block;
  width: 100%;
  max-width: 400px;
  height: 60px;
  margin: 0 auto;
  border-radius: 30px;
  background: url("./assets/header/ico_arrow.svg") no-repeat right 30px top 23px
    #3678f7;
  display: block;
  font-size: 2.4rem;
  font-weight: 700;
  color: #fff;
  border: none;
}
section#contact .defBtn {
  display: block;
}
section#contact .defBtn button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 400px;
  height: 60px;
  margin: 0 auto;
  border-radius: 30px;
  background: url("./assets/header/ico_arrow.svg") no-repeat right 30px top 23px
    #3678f7;
}
@media (max-width: 767.98px) {
  section#contact .defBtn button {
    height: 50px;
    border-radius: 25px;
    background: url("./assets/header/ico_arrow.svg") no-repeat right 30px top
      18px #3678f7;
  }
}
section#contact .defBtn button span {
  display: block;
  font-size: 2.4rem;
  font-weight: 700;
  color: #fff;
}
@media (min-width: 767.98px) {
  section#contact .defBtn button:hover {
    background-color: #195ace;
  }
}

.single-post-navigation {
  display: none !important;
}

.content-area.section-padding {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.elementor-5880 .elementor-element.elementor-element-da1a6e2 {
  margin-bottom: 0 !important;
}

.elementor-5880 .elementor-element.elementor-element-3f67eeb {
  padding: 0 !important;
}

section#enhanced {
  padding: 200px 0 !important;
  max-width: initial;
  background: #edf1fc;
}
@media (max-width: 767.98px) {
  section#enhanced {
    padding: 100px 0 !important;
  }
}
@media (max-width: 767.98px) {
  section#enhanced > .contInner .hdg_ttl02 {
    font-size: 3rem;
    margin: 0 auto 30px;
  }
  section#enhanced > .contInner .hdg_ttl02 > div {
    height: initial;
  }
}
section#enhanced > .contInner .ph {
  margin-bottom: 50px;
}
section#enhanced > .contInner .ph img {
  display: block;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}
section#enhanced > .contInner .hdg_ttl03 {
  margin-bottom: 30px;
}
section#enhanced > .contInner .hdg_ttl03 .inner {
  display: flex;
  justify-content: center;
  max-width: 1000px;
  margin: 0 auto;
}
@media (max-width: 767.98px) {
  section#enhanced > .contInner .hdg_ttl03 .inner {
    display: block;
  }
}
section#enhanced > .contInner .hdg_ttl03 .inner .left {
  display: flex;
  align-items: center;
  margin-right: 20px;
}
@media (max-width: 767.98px) {
  section#enhanced > .contInner .hdg_ttl03 .inner .left {
    width: 100%;
    margin-right: 0;
    justify-content: center;
  }
  section#enhanced > .contInner .hdg_ttl03 .inner .left svg {
    width: 80px;
    margin-left: 15px;
    margin-bottom: 10px;
  }
}
section#enhanced > .contInner .hdg_ttl03 .inner .right {
  display: flex;
  align-items: center;
  text-align: left;
}
@media (max-width: 767.98px) {
  section#enhanced > .contInner .hdg_ttl03 .inner .right {
    justify-content: center;
  }
}
section#enhanced > .contInner .hdg_ttl03 .inner .right .ttl {
  display: inline-block;
  font-family: "Helvetica" !important;
  font-size: 3rem;
  font-weight: 700;
  line-height: 1;
  text-align: center;
}
@media (max-width: 767.98px) {
  section#enhanced > .contInner .hdg_ttl03 .inner .right .ttl {
    font-size: 2.4rem;
    line-height: 1.2;
  }
}
section#enhanced > .contInner .block {
  position: relative;
  width: 100%;
  padding: 35px 50px;
  margin-bottom: 40px;
  border-radius: 10px;
  background: #fff;
  border: 2px solid #3678f7;
}
@media (max-width: 767.98px) {
  section#enhanced > .contInner .block {
    padding: 20px;
  }
}
section#enhanced > .contInner .block .inner {
  margin-bottom: 20px;
}
@media (max-width: 767.98px) {
  section#enhanced > .contInner .block .inner {
    display: block;
  }
}
section#enhanced > .contInner .block .inner .right {
  text-align: left;
}
section#enhanced > .contInner .block .inner .right .ttl {
  font-style: italic;
  font-size: 2.4rem;
  color: #3678f7;
  margin-bottom: 15px;
  text-align: center;
}
@media (max-width: 767.98px) {
  section#enhanced > .contInner .block .inner .right .ttl {
    line-height: 1.2;
  }
}
section#enhanced > .contInner .block .inner .right .txt {
  font-size: 2rem;
  margin: 0 0 25px;
  padding: 0 50px;
}
@media (max-width: 767.98px) {
  section#enhanced > .contInner .block .inner .right .txt {
    padding: 0;
    font-size: 1.8rem;
  }
}
section#enhanced > .contInner .block .ph {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 45px;
}
@media (max-width: 767.98px) {
  section#enhanced > .contInner .block .ph {
    display: block;
  }
}
section#enhanced > .contInner .block .ph .item {
  position: relative;
}
section#enhanced > .contInner .block .ph .item img {
  width: 100%;
}
section#enhanced > .contInner .block .ph .item:before {
  position: absolute;
  content: "";
  padding: 0 20px;
  height: 26px;
  line-height: 26px;
  top: 10px;
  left: 10px;
  border-radius: 5px;
  background: #3678f7;
  opacity: 0.79;
  color: #fff;
  text-align: center;
  font-size: 1.4rem;
}
@media (max-width: 767.98px) {
  section#enhanced > .contInner .block .ph .item:not(:last-of-type) {
    margin-bottom: 50px;
  }
}
section#enhanced > .contInner .block .ph .item:not(:last-of-type):after {
  position: absolute;
  content: "";
  width: 46px;
  height: 18px;
  background: url("./assets/header/ico_arrow02.svg") no-repeat left top;
  background-size: contain;
  right: -46px;
  top: 50%;
  margin-top: -9px;
}
@media (max-width: 767.98px) {
  section#enhanced > .contInner .block .ph .item:not(:last-of-type):after {
    width: 30px;
    height: 12px;
    right: initial;
    transform: rotate(90deg);
    bottom: -30px;
    left: 50%;
    margin-left: -12px;
    top: initial;
    margin-top: 0;
  }
}
section#enhanced > .contInner .block .ph .item:nth-child(1):before {
  content: "Original";
}
section#enhanced > .contInner .block .ph .item:nth-child(2):before {
  content: "AI only";
}
section#enhanced > .contInner .block .ph .item:nth-child(3):before {
  content: "AI + Professional Team";
}
section#enhanced .defBtn {
  margin-bottom: 30px;
}
#privacyPolicyModal,
#TermOfServiceModal,
#settlementModal,
#lawModal {
  margin: 150px auto !important;
  max-width: 1360px !important;
  min-height: calc(100vh - 365px);
}
#privacyPolicyModal .modal-body .effective-date {
  font-weight: bolder;
  margin-bottom: 20px;
}
#privacyPolicyModal .modal-body .effective-date span {
  font-weight: normal;
}

#TermOfServiceModal .modal-body h2,
#privacyPolicyModal .modal-body h2 {
  text-align: center;
  font-weight: 800;
  position: relative;
  display: inline-block;
  margin-bottom: 40px;
  margin-left: 45%;
  font-size: 22px;
}

#TermOfServiceModal .modal-body h2::after,
#privacyPolicyModal .modal-body h2::after {
  content: "";
  display: block;
  width: 250px;
  height: 4px;
  background-color: red;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -5px;
}
#privacyPolicyModal .modal-body .privacy_policy_item h3 {
  font-weight: bolder;
}
#footer span {
  color: #555;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.price-plan-feature {
  min-height: 40px;
  text-align: center;
  width: 100%;
  margin: auto;
  .plan-features {
    border-radius: 15px;
    background: #f6f3f9;
    padding: 20px;
    border: 2px solid #3678f7;
    .pricing_item_feature {
      text-align: left;
      /* min-height: 55px; */
    }
  }
}
.modal-law {
  h2 {
    text-align: center;
    font-size: 22px;
    position: relative;
    display: inline-block;
    margin-top: 10px;
  }
}
.modal-law .modal-body {
  text-align: center;
}
.modal-law table {
  margin: 40px 20px;
  width: 100%;
}
.modal-law table tr {
  border-bottom: 1px solid #000000;
}
.modal-law table tr td:first-child {
  font-weight: bold; /* Makes text bolder */
  width: 20%;
}
.modal-law table tr td {
  padding: 10px;
}
.modal-law table tr:nth-child(odd) {
  background-color: #f2f2f2; /* Light grey for odd rows */
}

.modal-law table tr:nth-child(even) {
  background-color: #ffffff; /* White for even rows */
}
.modal-law h2::after {
  content: "";
  display: block;
  width: 350px; /* Adjust the width as needed */
  height: 4px; /* Adjust the thickness of the underline */
  background-color: red;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -5px; /* Adjust the distance from the text */
}
section#cont10 {
  background: #f6f4f9;
}
@keyframes moveUp {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes moveUpAndReset {
  0%,
  100% {
    transform: translateY(0);
    opacity: 1;
  }
  50% {
    transform: translateY(-100%);
    opacity: 0;
  }
}
.service-name-title:hover {
  svg {
    animation: moveUp 1s ease-in-out infinite;
  }
}

#bs-example-navbar-collapse-1 {
  margin: 0 auto;
  margin-right: 0;
}
@media screen and (max-width: 1024px) {
  #menu {
    width: 100%;
    left: 0;
    right: 0;
    .btn-sign-up,
    .btn-login,
    #dropdownMenuButton {
      /* display: none; */
    }
  }
}
@media only screen and (max-width: 600px) {
  #menu {
    .btn-sign-up,
    .btn-login,
    #dropdownMenuButton {
      display: block;
    }
  }
}
@media (min-width: 600px) and (max-width: 1024px) {
  .header-swiper .swiper-slide .ph {
    display: none !important;
  }
  #mvMessage,
  section .contInner {
    width: 90%;
  }
  section#pricing .pricing-row .pricing-item {
    /* min-height: 336px; */
  }
  #startNow .inner {
    width: 90%;
    margin: auto;
  }
  section#pricing .pricing-row {
    width: 100%;
  }
  #mvMessage .txtWrap {
    width: 100% !important;
  }
  #mvMessage .ph {
    display: none;
  }
  section#cont07 .swiperWrap .swiper07 {
    width: 90%;
  }
}
@media only screen and (max-width: 600px) {
  #footer {
    .container {
      .text-right {
        display: grid;
        grid-template-columns: 1fr 1fr;
        text-align: center;
        a {
          margin-right: 0px !important;
        }
      }
    }
  }
}

@media (max-width: 767.98px) {
  section#why_us .swiper01 .swiper-button-prev,
  section#why_us .swiper01 .swiper-button-next {
    display: block;
    position: absolute;
  }
  section#why_us .swiper01 .swiper-button-prev {
    left: 0px;
  }
  section#why_us .swiper01 .swiper-button-next {
    right: 0px;
  }
  section#pricing .pricing-row .pricing-item-border {
    padding: 20px 0px;
  }
  section#pricing .pricing-row {
    width: 90%;
  }
  section#sample .swiperWrap .swiper05 {
    width: 80%;
  }
  div#services
    > .contInner
    .priceListPC
    tbody
    tr
    td.active
    .detail
    .service-description
    span.description,
  div#services
    > .contInner
    .priceList
    tbody
    tr
    td.active
    .detail
    .service-description
    span.description {
    margin: 0;
    margin-top: 30px;
  }
  div#services > .contInner .txt,
  div#services > .contInner .seePrice {
    padding: 0.9%;
  }
  section#try_photo .wrap .right .codeWrap #folder {
    margin-left: -25px;
    bottom: 20px;
  }
  section#try_photo .wrap .right #btnProcess span img {
    right: 15px;
  }
  #comingSoon {
    .new-btn {
      width: 40% !important;
    }
    .new-text {
      width: 60% !important;
    }
    h2 {
      font-size: 6rem !important;
      margin-top: 30px !important;
    }
    p {
      font-size: 1.3rem !important;
      margin-top: 30px !important;
    }
  }
}

@media screen and (max-width: 810px) {
  #menu.navbar-default .navbar-nav > li > a {
    padding: 10px 5px;
  }
  section#how_it_work .swiper04 .swiper-button-prev,
  section#how_it_work .swiper04 .swiper-button-next {
    top: 90px !important;
  }
  section#sample .swiperWrap .swiper-button-prev,
  section#sample .swiperWrap .swiper-button-next {
    top: 90px !important;
  }
  section#why_us .swiper01 .swiper-button-prev,
  section#why_us .swiper01 .swiper-button-next {
    top: 170px !important;
  }
  #menu .container {
    padding-left: 5px;
    padding-right: 5px;
    min-width: auto;
  }
  .navbar-fixed {
    .container {
      width: 100% !important;
    }
  }
  .swiper05 {
    height: 240px !important;
  }
  #free_usage_all_service .swiper05 {
    height: 470px !important;
  }
  section#sample .swiperWrap .swiper05 {
    width: 90%;
  }
  section#about .inner {
    margin: 0 20px 20px;
  }
  section#about .inner .right {
    margin-left: 20px;
  }
  section#cont07 .swiperWrap .swiper07 {
    width: 85%;
  }
  .contInner {
    width: 90%;
  }
  section#why_us .swiper-button-prev,
  section#why_us .swiper-button-next {
    display: block;
  }
  section#why_us .swiper-button-prev {
    left: 0px;
  }
  .swiper-rtl .swiper-button-pre {
    right: 0px;
  }
  section#why_us .swiper01 .swiper-slide {
    margin-right: 40px;
    margin-left: 10px;
  }
  section#try_photo .wrap .right #btnProcess {
    /* font-size: 1.2rem; */
  }
  section#try_photo .wrap .right #btnProcess span img,
  section#try_photo .wrap .right #btnProcess button img {
    right: 5%;
  }
  section#try_photo .wrap .right .codeWrap .drag {
    font-size: 1.8rem;
  }
  section#try_photo .wrap .right .codeWrap #folder {
    margin-left: -25px;
    bottom: 20px;
  }
  section#try_photo .wrap .right .forClick {
    /* font-size: 1.2rem; */
  }
  div#services > .contInner .txt,
  div#services > .contInner .seePrice {
    padding: 0;
  }
  div#services > .contInner .priceListPC thead th.team .inner .ttl:before,
  div#services > .contInner .priceListPC thead th.team .inner .ttl:after {
    display: none;
  }
  div#services > .contInner .priceListPC thead th.team .inner .ttl {
    padding-left: 5px;
  }
  div#services > .contInner .priceListPC tbody tr .ai-support,
  div#services > .contInner .priceList tbody tr .ai-support {
    padding: 4%;
  }
  section#how_it_work .swiper-button-prev,
  section#how_it_work .swiper-button-next {
    display: block;
  }
  section#how_it_work .swiperWrap {
    margin: 0;
  }
  .swiper04 {
    height: 250px !important;
  }
  .swiper .swiper-wrapper {
    width: 90%;
  }
  section#pricing > .contInner .txt {
    width: 100%;
  }
  section#pricing .pricing-row {
    width: 100%;
  }
  .swiper09 {
    height: 350px !important;
  }
  .swiper11 {
    height: 600px !important;
    padding: 0 5px !important;
  }
  #footer p {
    text-align: center !important;
    padding: 0px;
    margin-bottom: 10px;
  }
  #footer div {
    text-align: center !important;
  }
  div#services > .contInner .priceListPC thead th.team .inner .price_tag {
    width: 40px;
  }
  div#services > .contInner .priceListPC thead th.team .inner .price_tag span {
    font-size: 1.2rem;
    padding: 2px;
    height: 25px;
    margin-bottom: -10px;
    margin-left: -5px;
  }
  #startNow .inner .btn span {
    font-size: 2rem;
  }
  div#services > .contInner .priceListPC tbody tr td span .price_tag {
    margin-top: 30px;
    width: 90px;
  }
  div#services > .contInner .priceListPC tbody tr td span .price_tag span {
    font-size: 1.2rem;
  }
  section#reviews .swiperWrap .swiper09 .swiper-slide .review {
    display: block;
  }
}

@media screen and (max-width: 600px) {
  section#sample .swiperWrap .swiper-button-prev,
  section#sample .swiperWrap .swiper-button-next {
    top: 120px !important;
  }
  section#why_us .swiper01 .swiper-slide {
    margin-right: 40px;
    margin-left: 0px;
    width: 100% !important;
  }
  .swiper05 {
    height: 280px !important;
  }
  section#sample .swiperWrap .swiper05 {
    width: 80%;
  }
  section#about .inner .right {
    margin-left: 0px;
  }
  div#services > .contInner {
    width: 100%;
  }
  .modal-law table {
    margin: 20px 0px;
  }
  #TermOfServiceModal .modal-body h2,
  #privacyPolicyModal .modal-body h2 {
    margin-left: 20%;
  }
  #header .header-swiper .swiper-slide {
    /* height: 800px; */
  }
  .swiper .swiper-slide img {
    object-fit: cover;
  }
  #mvMessage {
    position: relative;
    display: grid;
    max-height: 1200px;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }
  #mvMessage .txtWrap {
    position: relative;
    width: 90% !important;
    grid-row: 2;
  }
  #mvMessage .catch {
    margin-bottom: 50px;
  }
  #startNow {
    margin: -50px 0px -30px;
    padding: 10px 0px;
  }
  #mvMessage .ph {
    grid-row: 1;
    margin-bottom: 20%;
    margin-top: 30%;
    width: 90% !important;
  }
  #header .swiper {
    /* position: relative !important; */
    height: 400px;
  }
  .swiper .swiper-slide .ph {
    top: 32%;
  }
  section#why_us .swiper01 .swiper-button-prev,
  section#why_us .swiper01 .swiper-button-next {
    top: 140px !important;
  }
  .price-plan-feature {
    .plan-features {
      padding: 10px;
      margin-left: 0px;
      margin-right: 0px;
    }
  }
}

@media (max-width: 1024px) {
  #footer p,
  #footer div {
    text-align: center;
    padding: 0px;
  }
}
#comingSoon {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100vh; /* Full viewport height */
  background: -webkit-linear-gradient(left, rgb(46, 111, 88), #337aee);
  text-align: center;
  img {
    margin-bottom: 60px;
    max-width: 400px;
    width: 40%;
    margin-top: 30%;
    min-width: 280px;
  }
  h2 {
    font-size: 8rem;
    color: #fff;
    letter-spacing: 0.08em;
    text-transform: uppercase;
  }
  p {
    color: #fff;
    font-size: 16px;
    line-height: 1.1em;
    letter-spacing: 0.02em;
    max-width: 700px;
    margin: auto;

    margin-top: 50px;
  }

  .new-btn {
    height: 50px;
    border: none;
    display: inline-block;
    margin-left: -5px;
    width: 28%;
    background: rgba(255, 255, 255, 0.3);
    color: #fff;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 0.02em;
    font-weight: 600;
    outline: 0;
    transition: all 0.3s linear;
  }

  .new-btn .fa {
    margin-right: 10px;
  }

  .new-btn:hover {
    background: rgba(255, 255, 255, 0.5);
  }

  .newsletter-form {
    max-width: 700px;
    margin: auto;
    margin-top: 30px;
  }
  .newsletter-form {
    background: rgba(255, 255, 255, 0.1);
  }

  .new-text {
    height: 50px;
    border: none;
    display: inline-block;
    width: 72%;
    background: none;
    box-shadow: none;
    outline: 0;
    padding: 0 20px;
    font-size: 16px;
    color: #fff;
  }

  .new-text::-webkit-input-placeholder {
    color: #fff;
  }

  .new-text::-moz-placeholder {
    color: #fff;
  }

  .new-text:-ms-input-placeholder {
    color: #fff;
  }

  .new-text:-moz-placeholder {
    color: #fff;
  }
}
@media screen and (max-width: 320px) {
  #mvMessage .ph {
    margin-bottom: 30%;
    margin-top: 50%;
  }
  section#why_us .swiper01 .swiper-slide {
    margin-right: 60px;
  }
  section#why_us .swiper01 .item .txt02 {
    padding-top: 0px;
  }
  section#why_us .swiper01 .swiper-slide {
    width: 90% !important;
  }
  section#why_us .swiper01 .item > .inner {
    height: 200px;
  }
  section#why_us .swiper01 .item > .inner .icon {
    height: 50px;
  }
  section#why_us .swiper01 .item > .inner .txt01 {
    font-size: 1.7rem;
    justify-content: center;
  }
  div#services > .contInner .priceListSP summary .team .inner .ttl {
    font-size: 1.6rem;
  }
}
.swiper-wrapper {
  height: auto !important;
}
.full-height {
  min-height: calc(100% - 66px);
  display: flex;
  justify-content: center;
  align-items: center;
  .download-note {
    background: #ffa3ad;
    border-radius: 5px;
  }
  .hdg_ttl02 {
    margin-bottom: 30px !important;
  }
  .btn {
    margin: auto;
    color: #fff;
    font-size: 1.8rem;
    font-weight: 700;
    text-align: center;
    align-items: center;
    min-width: 200px;
    height: 45px;
    justify-content: center;
  }
  .txt {
    text-align: center;
    max-width: 60%;
    margin: auto;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .ph .item:not(:last-of-type):after {
    position: absolute;
    content: "";
    width: 46px;
    height: 18px;
    background: url(http://localhost:3000/static/media/ico_arrow02.460ca22….svg)
      no-repeat left top;
    background-size: contain;
    right: -46px;
    top: 50%;
    margin-top: -9px;
  }
  .sub-title {
    margin-top: 10px;
  }
  .txt p {
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 0px;
  }
  .txt p.sub-title-2 {
    font-size: 1.4rem;
    font-weight: 600;
  }
  @media screen and (max-width: 810px) {
    .txt {
      max-width: 100%;
    }
    .txt p {
      font-size: 1.6rem;
    }
  }
  @media screen and (max-width: 767.98px) {
    .hdg_ttl02 {
      margin-bottom: 10px !important;
      font-size: 2.2rem;
    }
    .txt {
      max-width: 100%;
      font-size: 1.4rem;
    }
    .txt p {
      font-size: 1.4rem;
      margin-bottom: 5px;
    }
    .hdg_ttl02 > div:before,
    .hdg_ttl02 > div:after {
      display: none;
    }
    .hdg_ttl02 > div {
      padding: 0 !important;
    }
  }
  .contInner {
    text-align: center;
    margin: auto !important;
  }
  .photoItems .item .i {
    width: 10% !important;
    height: 80px !important;
    border-radius: 5px;
    overflow: hidden; /* Hide any overflow */
    @media screen and (max-width: 767.98px) {
      height: 40px !important;
    }
  }
  .photoItems .item .i > img {
    position: relative; /* Make the container positionable */
    border-radius: 5px;
    width: auto !important;
  }

  .legalInfos {
    font-size: 1rem;
    font-weight: 500;
    margin-top: 10px;
  }
  .legalInfos a {
    text-decoration: underline;
  }
  .free-uploader {
    width: 70% !important;
    margin: auto;
    min-height: 50%;
    .codeWrap {
      margin: auto !important;
      justify-content: center !important;
      align-items: center !important;
      padding: 0 !important;
      min-height: 45vh;
      overflow: hidden !important;
      display: flex !important;
      .malware-protection {
        width: 100px;
      }
      .drag {
        position: relative !important;
        margin: auto !important;
        top: 0 !important;
        font-size: 1.6rem !important;
      }
      div {
        max-height: 45vh;
      }
      img {
        width: 100% !important;
        margin: auto !important;
        height: 100%;
        object-fit: contain !important;
        max-height: 45vh;
      }
    }
    .left {
      background: transparent !important;
      height: 45vh !important;
      box-shadow: none !important;
      img {
        box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.8);
        border-radius: 10px;
        width: auto !important;
      }
    }
    @media screen and (max-width: 767.98px) {
      .left {
        height: 35vh !important;
      }
      .forClick {
        font-size: 1rem !important;
      }
    }
    .photoHere {
      margin: 10px auto;
      font-weight: 500 !important;
      text-align: center !important;
    }
    .forClick {
      margin-top: 15px;
      color: #000;
      font-weight: 500 !important;
      font-style: normal;
      white-space: normal !important;
    }
    .button-container {
      margin-top: 20px;
      width: 100%;
    }
    .left {
      width: auto !important;
    }
    .action-buttons {
      display: flex;
      gap: 10px;
      justify-content: center;
    }

    .action-button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 12px 24px;
      border-radius: 8px;
      font-weight: 700;
      font-size: 1.8rem;
      border: none;
      cursor: pointer;
      transition: all 0.3s ease;
      min-width: 200px;
    }
    @media screen and (max-width: 767.98px) {
      .action-button {
        padding: 8px 14px;
      }
    }
    .action-button span {
      margin-right: 8px;
    }

    .button-icon {
      width: 20px;
      height: 20px;
    }

    .download-button {
      background-color: #007bff;
      color: white;
    }

    .download-button:hover {
      background-color: #0056b3;
    }

    .retry-button {
      background-color: #6c757d;
      color: white;
    }

    .retry-button:hover {
      background-color: #545b62;
    }

    @media (max-width: 768px) {
      .action-buttons {
        flex-direction: column;
        align-items: center;
        gap: 0px;
      }

      .action-button {
        width: 100%;
        margin-bottom: 10px;
      }
    }
    .service-list {
      width: 100%;
      padding: 5px 0;
    }

    .service-row {
      display: flex;
      flex-wrap: wrap;
      padding: 5px;
      justify-content: center;
    }
    .service-icon {
      width: 30px;
      height: 30px;
      border-radius: 30px !important;
    }
    .service-item {
      display: inline-flex;
      align-items: center;
      gap: 4px;
      padding: 2px 4px;
      /* background: #f5f5f5; */
      border-radius: 5px;
      white-space: nowrap;
      margin: 4px;
      justify-content: center;
      font-size: 1.6rem;
      cursor: pointer;
      border: none;
      box-shadow: 0px 3px 5px rgba(99, 147, 245, 0.8);
    }

    .service-item.active {
      background: #007bff;
      color: white;
    }

    .service-item.inactive {
      opacity: 0.6;
    }

    .service-name {
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .process_loading {
      position: absolute;
      max-width: 25px !important;
    }
    .process_image {
      opacity: 0.9;
    }
  }
  @media screen and (max-width: 810px) {
    .free-uploader {
      width: 100% !important;
    }
  }

  #btnProcess span {
    font-style: normal;
    width: auto !important;
    min-width: 200px !important;
    padding-left: 15px;
  }
  #btnProcess.disabled span {
    background: #768091 !important;
  }
}

#inviteUserModal {
  font-family: "Helvetica" !important;
  .modal-dialog {
    max-width: 600px;
    margin: 50px auto;
  }
  .modal-header {
    text-align: center;
    padding: 5px;
  }

  .modal-title {
    text-align: center;
    font-size: 2rem;
    margin: auto;
    font-family: "Helvetica" !important;
    font-weight: 700;
    span {
      color: #3678f7;
    }
  }

  .key-point-box {
    min-height: 165px;
    width: 100%;
    margin-top: 0;
  }
  .key-point-box-2 {
    margin-bottom: 0px;
  }
  @media screen and (max-width: 767.98px) {
    .modal-title {
      font-size: 1.8rem;
    }
    .key-point-box {
      min-height: 115px;
      width: 100%;
      margin-top: 0px;
      margin-bottom: 20px;
    }
    #inviteUserModal {
      .box__des {
        font-size: 1.2rem !important;
      }
    }
  }
  .box__icon {
    position: relative;
    color: #000;
    border-style: dashed;
    border-width: 1px 1px 1px 1px;
    border-radius: 100px 100px 100px 100px;
    width: 100px;
    height: 100px;
    text-align: center;
    margin: 0 auto 15px;
    padding: 0;
  }
  .box__icon:before {
    position: absolute;
    content: "";
    width: 22px;
    height: 22px;
    border-radius: 11px;
    transform: rotate(0) scale(1);
  }
  .box__icon:after {
    position: absolute;
    width: 26px;
    font-weight: 700;
    height: 26px;
    font-size: 1.6rem;
    border-radius: 22px;
    background-color: #fff;
    transform: rotate(0) scale(1);
  }
  .box__icon_0:before {
    background-color: #f23674;
    right: 0px;
    bottom: 10px;
  }
  .box__icon_0:after {
    content: "1";
    left: 0px;
    color: #f23674;
    box-shadow: 0 10px 20px rgba(242, 54, 116, 0.4);
  }
  .box__icon_1:before {
    background-color: #ffa03a;
    left: 0px;
  }
  .box__icon_1:after {
    content: "2";
    right: 0px;
    color: #ffa03a;
    bottom: 10px;
    box-shadow: 0 10px 10px 0 rgba(255, 160, 58, 0.38);
  }
  .box__icon_2:before {
    background-color: #1cacf3;
    right: 0px;
    bottom: 10px;
  }
  .box__icon_2:after {
    content: "3";
    left: 0px;
    color: #1cacf3;
    box-shadow: 0 10px 20px 0 rgba(28, 172, 243, 0.33);
  }
  .box__icon_3:before {
    background-color: #15de9b;
    left: 0px;
  }
  .box__icon_3:after {
    content: "4";
    right: 0px;
    color: #15de9b;
    bottom: 10px;
    box-shadow: 0 10px 20px 0 rgba(21, 222, 155, 0.47);
  }

  .box__des {
    font-size: 1.4rem;
    font-weight: 400;
    text-align: center;
    color: #545b62;
    margin-bottom: 20px;
  }
  .box__title {
    font-size: 1.8remrem;
    font-weight: 700;
    text-align: left;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }
  @media screen and (max-width: 767.98px) {
    .box__title {
      font-size: 1.4rem;
      margin-bottom: 0px;
    }
    .box__des {
      font-size: 1rem;
    }
  }
}
@media screen and (max-width: 767.98px) {
  #inviteUserModal {
    .modal-dialog {
      margin: 80px 10px;
    }
  }
}
.new_item {
  border-radius: 10px;
  padding: 0 5px;
  box-shadow: 0 2px 10px 0 rgb(104 92 92 / 55%);
  height: 99%;
  img {
    border-radius: 10px;
    height: 220px !important;
    position: relative !important;
  }
  .title {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 1.6rem;
    font-weight: 700;
    color: #3678f7;
  }
  .txt p {
    font-size: 1.4rem;
    font-weight: 400;
    margin-bottom: 10px;
  }
}

/* Tooltip styles */
[data-tooltip] {
  position: relative;
  cursor: pointer;
}

[data-tooltip]:before,
[data-tooltip]:after {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
  z-index: 999;
}

[data-tooltip]:before {
  content: "";
  border: 6px solid transparent;
  border-top-color: rgba(0, 0, 0, 0.8);
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
}

[data-tooltip]:after {
  content: attr(data-tooltip);
  background-color: rgba(0, 0, 0, 0.8) !important;
  color: white;
  padding: 6px 12px;
  font-size: 1.4rem;
  white-space: nowrap;
  border-radius: 4px;
  bottom: calc(100% + 6px);
  left: 50%;
  transform: translateX(-50%);
}

[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  opacity: 1;
}

/* Position variants */
[data-tooltip-position="bottom"]:before {
  bottom: auto;
  top: 100%;
  border-top-color: transparent;
  border-bottom-color: rgba(0, 0, 0, 0.8);
}

[data-tooltip-position="bottom"]:after {
  bottom: auto;
  top: calc(100% + 16px);
}

[data-tooltip-position="left"]:before {
  bottom: auto;
  left: auto;
  right: 100%;
  top: 50%;
  transform: translateY(-50%);
  border-top-color: transparent;
  border-left-color: rgba(0, 0, 0, 0.8);
}

[data-tooltip-position="left"]:after {
  bottom: auto;
  left: auto;
  right: calc(100% + 6px);
  top: 50%;
  transform: translateY(-50%);
}

[data-tooltip-position="right"]:before {
  bottom: auto;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  border-top-color: transparent;
  border-right-color: rgba(0, 0, 0, 0.8);
}

[data-tooltip-position="right"]:after {
  bottom: auto;
  left: calc(100% + 6px);
  top: 50%;
  transform: translateY(-50%);
}

/* Service card styles */
.service-cards {
  width: 80%;
  margin: auto;
}

.service-card {
  background: #fff;
  /* border: 1px solid #3678f7; */
  border-radius: 10px;
  padding: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.6);
  transition: all 0.3s ease;
  height: auto;
  min-height: 450px;
}

/* .service-card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
} */

.service-card .compare-container {
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 15px;
  max-height: 270px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.service-card .service-title {
  font-size: 1.8rem;
  font-weight: 700;
  color: #3678f7;
  margin: 15px 0;
  text-align: center;
}

.service-card .service-description {
  font-size: 1.6rem;
  color: #555;
  line-height: 1.5;
  text-align: center;
  min-height: 120px;
}
@media screen and (max-width: 810px) {
  .service-cards {
    width: 100%;
  }
  .service-card {
    margin-bottom: 20px;
  }
}
@media (max-width: 767.98px) {
  .service-cards {
    width: 100%;
  }
  .service-card {
    margin-bottom: 20px;
  }

  .service-card .service-title {
    font-size: 1.6rem;
  }

  .service-card .service-description {
    font-size: 1.4rem;
  }
}
.image-container {
  position: relative;
  overflow: hidden;
}

.image-container::after {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 50%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.6),
    transparent
  );
  transform: skewX(-25deg);
}

.image-container.scanning::after {
  animation: scan 1s ease-in-out;
}

@keyframes scan {
  0% {
    left: -100%;
  }
  100% {
    left: 200%;
  }
}

.invite-user-modal-btn-group {
  display: flex;
  justify-content: center;
  gap: 0px;
}
@media screen and (max-width: 810px) {
  .invite-user-modal-btn-group {
    align-items: center;
    .btn {
      font-size: 1.8rem;
      margin-top: 0px;
      height: 45px;
      min-width: 170px;
    }
  }
}
.action-note {
  font-size: 1.2rem;
  color: red;
  margin-top: 10px;
}
.break_header_line {
  display: block;
}

.modal-sub-invite-title {
  font-weight: 700;
  font-size: 1.8rem;
  margin-bottom: 10px;
  text-align: center;
}
@media screen and (max-width: 810px) {
  .modal-sub-invite-title {
    font-size: 1.6rem;
  }
}
@media (max-width: 765px) and (min-width: 440px) {
  div#free_usage_service .ph .item img {
    max-height: 110px;
    width: auto;
    margin-left: 20%;
  }
  .key-point-box {
    min-height: 115px;
  }
  div#free_usage_service .ph .item span {
    margin-left: 20%;
  }
}
@media (max-width: 500px) and (min-width: 440px) {
  div#free_usage_service .ph .item span {
    margin-left: 0%;
  }
  div#free_usage_service .ph .item img {
    margin-left: 0%;
  }
}
